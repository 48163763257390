import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import CountryList from '../utils/CountryList';
import {
	TextField,
	FormControlLabel,
	MenuItem,
	Dialog,
	DialogContent,
	DialogActions,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	InputAdornment
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TermsConditionsDialog } from 'components/terms';
import { isArray } from 'util';
import Validations from '../utils/Validations';
import logoStripe from '../../assets/images/logos/powered_by_stripe.svg';
import { CellPhoneInput } from 'components/commons';
import { SpinnerV, SnackBar, ConfirmDialog } from '../widgets';
import { CardForm } from 'components/forms';
import formatPhonePH from 'components/utils/formatPhonePH';
import { paypalData } from 'components/payment/paypal';
import { disclaimer } from '../payment/disclaimer';
import VoucherInput from '../payment/VoucherInput';
import { CashData } from '../payment/Cash';
import { transferData } from '../payment/transfer';

function InfoDialog(props) {
	return (
		<Dialog open={props.open}>
			<DialogContent>
				<div className="font-normal text-base leading-relaxed w-full">
					Si escoges esta forma de pago, al finalizar el proceso uno de nuestros
					ejecutivos de{' '}
					<span className="text-pink-500 font-bold"> asistensi</span> se pondrá
					en contacto con Ud. con la finalidad de coordinar una cita para la
					entrega del pago en efectivo.
				</div>
			</DialogContent>
			<DialogActions>
				<button onClick={props.handleClose} className="white-button-nav">
					Close
				</button>
			</DialogActions>
		</Dialog>
	);
}

const paymentMethodName = {
	Z: 'de Zelle',
	P: 'de PayPal',
	T: 'del Banco'
};

const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE_RESUME;

class PaymentForm extends Component {
	state = {
		openDialog: false,
		methods: [],
		dialog: false,
		openAlert: false,
		messageAlert: '',
		changeCoupon: false,
		showConfirm: false,
		showSpinner: false,
		activePaymentsForms: []
	};

	handleClose = () => this.setState({ openDialog: false });

	closeDialog = (param) => {
		this.setState({ dialog: false });
		this.handleCheckbox('acceptTerms', param);
	};

	handleCoupon = async (e) => {
		e.preventDefault();
		const { coupon } = this.props;
		const response = await this.props.validateCoupon({ coupon });
		this.setState({ ...response });
	};

	handleInputCoupon = (event) => {
		const { value } = event.target;
		this.props.saveCoupon({ percent_off: 0, coupon: value });
		this.props.calculations();
	};

	handleChangeCoupon = () => {
		this.setState({ changeCoupon: true, showConfirm: false });
		this.props.saveCoupon({ percent_off: 0, coupon: '' });
	};

	handleInput = (event) => {
		let { name, value } = event.target;
		let payment = this.props.payment;
		payment[name] = value;
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		this.props.calculations();
		this.setState({ openDialog: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	handleInputSelect = (event) => {
		let { name, value } = event.target;
		let payment = this.props.payment;
		payment[name] = value;
		payment.payment_voucher = null;
		payment.reference_number = '';
		payment.client_card.number = '';
		payment.client_card.exp = '';
		payment.client_card.cvc = '';
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		if (value === 'E') this.setState({ openDialog: true });
		else this.setState({ openDialog: false });
		this.props.onIsRender((prev) => !prev);
		this.props.calculations();
	};

	async componentDidMount() {
		let { payment, isoCode, interval } = this.props;
		const default_payment =
			interval &&
			this.props.paymentMethods?.find((m) => m.recurrent_interval === interval)
				? this.props.paymentMethods.find(
						(m) => m.recurrent_interval === interval
				  )
				: this.props.paymentMethods?.find((pm) => pm.default === true);

		if (default_payment) {
			payment.payment_form = default_payment;
			payment.payment_interval = interval
				? interval
				: default_payment.recurrent_interval;
			this.props.calculateTotalPayment({
				isoCode,
				total: default_payment?.total,
				total_currency: default_payment?.currency
			});
		} else {
			const default_method =
				isArray(this.props.paymentMethods) &&
				this.props.paymentMethods.length > 0
					? this.props.paymentMethods[0]
					: {};
			payment.payment_form = default_method;
			payment.payment_interval = interval
				? interval
				: default_method.recurrent_interval;
			this.props.calculateTotalPayment({
				isoCode,
				total: default_method?.total,
				total_currency: default_method?.currency
			});
		}

		payment.payment_methods = this.props.paymentMethods;
		this.props.saveState({ payment });

		if (this.props.active_payment_forms) {
			const forms = this.props.payment?.payment_form?.paymentForms?.filter(
				(p) => this.props.active_payment_forms.includes(p.value)
			);
			this.setState({ activePaymentsForms: forms });
		}
	}

	componentDidUpdate(prevProps) {
		let { payment } = this.props;
		if (this.props.active_payment_forms !== prevProps.active_payment_forms) {
			const forms = this.props.payment?.payment_form?.paymentForms.filter((p) =>
				this.props.active_payment_forms.includes(p.value)
			);
			this.setState({ activePaymentsForms: forms });
			payment.payment_method = 'default';
			this.props.saveState({ payment });
		}
	}

	handleInputSelectPaymentForm = async (event) => {
		let { value } = event.target;

		// set interval
		await this.props.actions.setInterval(value);

		let { payment, isoCode } = this.props;
		const form = payment.payment_methods.find(
			(m) => m.recurrent_interval === value
		);

		payment.payment_form = form;
		payment.payment_interval = value;
		payment.payment_method = '';

		Validations.validatePaymentForm(
			payment,
			this.props.saveState,
			'payment_interval',
			value
		);

		this.props.calculateTotalPayment({
			isoCode,
			total: form?.total,
			total_currency: form?.currency
		});
		this.props.saveState({ payment });
		this.props.calculations();
	};

	handleInputFile = (event) => {
		let { name, files } = event.target;
		let payment = this.props.payment;
		payment[name] = files[0];
		this.props.saveState({ payment });
		Validations.validatePaymentForm(
			payment,
			this.props.saveState,
			name,
			files[0]
		);
		this.props.calculations();
		this.setState({ openDialog: false });
	};

	handlePhone = (name, value) => {
		let payment = this.props.payment;
		payment[name] = name.includes('phone') ? formatPhonePH(value) : value;
		this.props.saveState({ payment });
		Validations.validatePaymentForm(payment, this.props.saveState, name, value);
		this.props.calculations();
	};

	handleCheckbox = (e, param) => {
		let payment = this.props.payment;
		if (e === 'acceptTerms') {
			payment.acceptTerms = param;
			Validations.validatePaymentForm(
				payment,
				this.props.saveState,
				'acceptTerms',
				param
			);
		} else {
			const { name } = e.target;
			payment[name] = param;
			Validations.validatePaymentForm(
				payment,
				this.props.saveState,
				name,
				param
			);
		}
		this.props.calculations();
		this.props.saveState({ payment });
		this.setState({ openDialog: false });
	};

	calculateRechargeAmount = (recharge) => {
		const { totalPayment, isoCode, total_currency } = this.props;
		if (!recharge || !totalPayment || !total_currency) return '';
		return (
			<span className="text-xs text-pink-500">
				+ {isoCode}. {((recharge * total_currency[isoCode]) / 100).toFixed(2)}
			</span>
		);
	};

	handlePhoneInput = (telNumber) => this.handlePhone('phone_one', telNumber);

	payment_inputs(payment) {
		return (
			<Accordion
				className="border border-gray-300 shadow-none rounded-none m-0 mt-m-1 delete"
				expanded={this.props.expanded === 'payment-person'}
				onChange={this.props.handleChange('payment-person')}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className="text-pink-500" />}
				>
					<div className="flex-center w-full">
						<span className="text-base font-bold text-gray-800 leading-relaxed">
							Payor Information
						</span>
					</div>
				</AccordionSummary>
				<AccordionDetails className="bg-gray-100 border-t">
					<div className="flex flex-wrap">
						<FormControlLabel
							className="w-full m-0"
							label={
								<span
									className={`text-gray-800 text-base ${
										this.props.useTitularData === true ? 'font-bold' : 'normal'
									}`}
								>
									Use my account information
								</span>
							}
							control={
								<Checkbox
									name="useTitularData"
									required={true}
									className={
										this.props.useTitularData === true
											? 'checkBoxActive'
											: 'checkBox'
									}
									onClick={() =>
										this.props.handleUseTitularData(
											this.props.useTitularData === true ? false : true
										)
									}
									checked={this.props.useTitularData}
									inputProps={{
										title: 'Check this box if you wish to continue'
									}}
								/>
							}
						/>
						<div className="w-full flex flex-wrap payment-form">
							{this.props.useTitularData === false && (
								<TextField
									name={'first_name'}
									label="First Name"
									placeholder="Enter first name"
									type="text"
									helperText={payment.formErrors.first_name}
									variant="outlined"
									error={true}
									className="wInputTwo"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root:
												!payment.first_name && !payment.formErrors.first_name
													? 'cssLabel'
													: payment.formErrors.first_name
													? 'cssLabelError'
													: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline:
												!payment.first_name && !payment.formErrors.first_name
													? 'cssInput'
													: payment.formErrors.first_name
													? 'cssInputError'
													: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.first_name}
									required={true}
									onChange={(event) => this.handleInput(event)}
								/>
							)}
							{this.props.useTitularData === false && (
								<TextField
									name={'middle_name'}
									label="Middle Name"
									placeholder="Enter middle name"
									type="text"
									helperText={payment.formErrors.middle_name}
									variant="outlined"
									error={true}
									className="wInputTwo"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root:
												!payment.middle_name && !payment.formErrors.middle_name
													? 'cssLabel'
													: payment.formErrors.middle_name
													? 'cssLabelError'
													: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline:
												!payment.middle_name && !payment.formErrors.middle_name
													? 'cssInput'
													: payment.formErrors.middle_name
													? 'cssInputError'
													: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.middle_name}
									required={false}
									onChange={(event) => this.handleInput(event)}
								/>
							)}
							{this.props.useTitularData === false && (
								<TextField
									name={'last_name'}
									label="Last Name"
									placeholder="Enter last name"
									type="text"
									helperText={payment.formErrors.last_name}
									variant="outlined"
									error={true}
									className="wInputTwo"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.last_name
												? 'cssLabel'
												: payment.formErrors.last_name
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline:
												!payment.last_name && !payment.formErrors.last_name
													? 'cssInput'
													: payment.formErrors.last_name
													? 'cssInputError'
													: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.last_name}
									required={true}
									onChange={this.handleInput}
								/>
							)}

							{this.props.useTitularData === false && (
								<TextField
									name={'dni'}
									label="ID Number"
									placeholder="Enter ID number"
									helperText={payment.formErrors.dni}
									variant="outlined"
									error={true}
									className="wInputTwo"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root:
												!payment.dni && !payment.formErrors.dni
													? 'cssLabel'
													: payment.formErrors.dni
													? 'cssLabelError'
													: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline:
												!payment.dni && !payment.formErrors.dni
													? 'cssInput'
													: payment.formErrors.dni
													? 'cssInputError'
													: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.dni}
									required={true}
									onChange={this.handleInput}
								/>
							)}
							{this.props.useTitularData === false && (
								<TextField
									name={'email'}
									label="Email Address"
									placeholder="Enter email address "
									type="email"
									helperText={payment.formErrors.email}
									variant="outlined"
									error={true}
									className="wInputTwo"
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root:
												!payment.email && !payment.formErrors.email
													? 'cssLabel'
													: payment.formErrors.email
													? 'cssLabelError'
													: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline:
												!payment.email && !payment.formErrors.email
													? 'cssInput'
													: payment.formErrors.email
													? 'cssInputError'
													: 'cssInputSuccess'
										}
									}}
									margin="normal"
									value={payment.email}
									required={true}
									onChange={this.handleInput}
								/>
							)}
							{this.props.useTitularData === false && (
								<CellPhoneInput
									label="Mobile number"
									addClass="wInputTwo"
									value={payment.phone_one}
									onChange={this.handlePhoneInput}
									error={payment.formErrors.phone_one}
									required={true}
								/>
							)}
							<TextField
								name={'zip_zone'}
								label="Zip Code"
								placeholder="Enter zip code"
								type="number"
								helperText={payment.formErrors.zip_zone}
								variant="outlined"
								error={true}
								className="wInputTwo"
								FormHelperTextProps={{
									classes: { root: 'cssHelperTextError' }
								}}
								InputLabelProps={{
									classes: {
										root:
											!payment.zip_zone && !payment.formErrors.zip_zone
												? 'cssLabel'
												: payment.formErrors.zip_zone
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!payment.zip_zone && !payment.formErrors.zip_zone
												? 'cssInput'
												: payment.formErrors.zip_zone
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								margin="normal"
								value={payment.zip_zone}
								required={true}
								onChange={this.handleInput}
							/>

							<TextField
								select
								name={'country'}
								label="Country"
								helperText={payment.formErrors.country}
								variant="outlined"
								error={true}
								className={`wInputTwo ${
									payment.country === 'default' && 'default'
								}`}
								FormHelperTextProps={{
									classes: { root: 'cssHelperTextError' }
								}}
								InputLabelProps={{
									classes: {
										root:
											!payment.country && !payment.formErrors.country
												? 'cssLabel'
												: payment.formErrors.country
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!payment.country && !payment.formErrors.country
												? 'cssInput'
												: payment.formErrors.country
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								SelectProps={{
									IconComponent: KeyboardArrowDownIcon
								}}
								margin="normal"
								value={payment.country}
								required={true}
								onChange={this.handleInput}
							>
								<MenuItem value="default" hidden disabled>
									Select country
								</MenuItem>
								{CountryList.map((country, i) => (
									<MenuItem key={i} value={country.name_en}>
										{country.name_en}
									</MenuItem>
								))}
							</TextField>

							<TextField
								name={'state'}
								label="State"
								placeholder="Enter state"
								type="text"
								helperText={payment.formErrors.state}
								variant="outlined"
								error={true}
								className="wInputTwo"
								FormHelperTextProps={{
									classes: { root: 'cssHelperTextError' }
								}}
								InputLabelProps={{
									classes: {
										root:
											!payment.state && !payment.formErrors.state
												? 'cssLabel'
												: payment.formErrors.state
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!payment.state && !payment.formErrors.state
												? 'cssInput'
												: payment.formErrors.state
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								margin="normal"
								value={payment.state}
								required={true}
								onChange={this.handleInput}
							/>

							<TextField
								name={'city'}
								label="City"
								placeholder="Enter city"
								type="text"
								helperText={payment.formErrors.city}
								variant="outlined"
								error={true}
								className="wInputTwo"
								FormHelperTextProps={{
									classes: { root: 'cssHelperTextError' }
								}}
								InputLabelProps={{
									classes: {
										root:
											!payment.city && !payment.formErrors.city
												? 'cssLabel'
												: payment.formErrors.city
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!payment.city && !payment.formErrors.city
												? 'cssInput'
												: payment.formErrors.city
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								margin="normal"
								value={payment.city}
								required={true}
								onChange={this.handleInput}
							/>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
		);
	}

	interval(param) {
		if (param === 'month') {
			return 'Monthly';
		} else if (param === 'year') {
			return 'Annual';
		} else if (param === 'quarterly') {
			return 'Quarterly';
		} else if (param === 'semiannual') {
			return 'Semiannual';
		} else {
			return '';
		}
	}

	render() {
		const { payment, autoPayRenew, titular, isoCode, symbol, total_currency } =
			this.props;

		return (
			<div className="w-full">
				<style global jsx>
					{`
						.mt-m-1 {
							margin-top: -1px !important;
						}
						.delete::before {
							height: 0px;
						}
					`}
				</style>
				{this.state.showSpinner === true && <SpinnerV />}
				<div className="w-full">
					{!autoPayRenew && this.payment_inputs(payment)}
					<Accordion
						className="border border-gray-300 shadow-none rounded-none m-0 mt-m-1 delete"
						expanded={this.props.expanded === 'payment-info'}
						onChange={this.props.handleChange('payment-info')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon className="text-pink-500" />}
						>
							<div className="flex items-center w-full justify-between">
								<span className="text-base font-bold text-gray-800 leading-relaxed">
									Payment Method
								</span>
							</div>
						</AccordionSummary>
						<AccordionDetails className="bg-gray-100 border-t">
							<div className="w-full flex flex-wrap">
								<TextField
									select
									label="Billing Cycle"
									name="payment_interval"
									value={payment.payment_interval}
									onChange={(e) => this.handleInputSelectPaymentForm(e)}
									variant="outlined"
									error={true}
									className={`wInputTwo ${
										payment.payment_interval === 'default' && 'default'
									}`}
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.payment_interval
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.payment_interval
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									SelectProps={{
										IconComponent: KeyboardArrowDownIcon
									}}
									helperText={payment.formErrors.payment_interval}
									margin="normal"
								>
									<MenuItem value="default" hidden disabled>
										Select billing cycle
									</MenuItem>
									{this.props.paymentMethods?.map((form, i) => (
										<MenuItem key={i} value={form.recurrent_interval}>
											{this.interval(form.recurrent_interval)}
										</MenuItem>
									))}
								</TextField>
								<TextField
									select
									label="Payment Method"
									name="payment_method"
									value={payment.payment_method}
									onChange={(e) => this.handleInputSelect(e)}
									variant="outlined"
									error={true}
									className={`field-responsive wInputTwo m-t25 ${
										payment.payment_method === 'default' && 'default'
									}`}
									FormHelperTextProps={{
										classes: { root: 'cssHelperTextError' }
									}}
									InputLabelProps={{
										classes: {
											root: !payment.payment_method
												? 'cssLabel'
												: payment.formErrors.payment_method
												? 'cssLabelError'
												: 'cssLabelSuccess'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: !payment.payment_method
												? 'cssInput'
												: payment.formErrors.payment_method
												? 'cssInputError'
												: 'cssInputSuccess'
										}
									}}
									SelectProps={{
										IconComponent: KeyboardArrowDownIcon
									}}
									helperText={payment.formErrors.payment_method}
									margin="normal"
								>
									<MenuItem value="default" hidden disabled>
										Select payment method
									</MenuItem>
									{this.state.activePaymentsForms &&
									this.state.activePaymentsForms.length > 0 ? (
										this.state.activePaymentsForms.map((method, i) => (
											<MenuItem key={i} value={method.value}>
												{method.name}&nbsp;
												{this.calculateRechargeAmount(method.recharge)}
											</MenuItem>
										))
									) : (
										<MenuItem value="empty" hidden disabled>
											Select payment method
										</MenuItem>
									)}
								</TextField>
								{this.props.is_back_coupon !== true ||
								this.state.changeCoupon === true ? (
									<TextField
										name={'coupon'}
										label="Promotional Code"
										placeholder="Enter Code (*optional)"
										type="text"
										variant="outlined"
										className="field-responsive wInputTwo m-t25"
										FormHelperTextProps={{
											classes: { root: 'cssHelperTextError' }
										}}
										InputLabelProps={{
											classes: {
												root: 'cssLabel'
											},
											shrink: true
										}}
										InputProps={{
											classes: {
												notchedOutline: 'cssInput'
											},
											endAdornment: (
												<InputAdornment position="end">
													<button
														className="button-right"
														aria-label="send coupon"
														onClick={(e) => this.handleCoupon(e)}
													>
														<SearchIcon />
													</button>
												</InputAdornment>
											)
										}}
										margin="normal"
										value={this.props.coupon || ''}
										onChange={(e) => this.handleInputCoupon(e)}
									/>
								) : (
									<button
										className="cursor-pointer font-bold text-pink-500 text-xs font-poppins uppercase tracking-extra mt-2 ml-2"
										onClick={() => this.setState({ showConfirm: true })}
									>
										Aplicar nuevo cupón
									</button>
								)}
								{this.props.free_trial === true &&
									payment.payment_method !== 'S' && (
										<div className="bg-pink-light p-20 text-pink-500 font-14 leading-relaxed mt-5 rounded w-full"></div>
									)}
								{this.props.free_trial === true &&
									payment.payment_method === 'S' &&
									(this.props.coupon === 'job-and-talent' ||
										this.props.coupon === 'caracas-press-club-10' ||
										this.props.coupon === 'empresas-polar-10') && (
										<p className="bg-purple-200 p-5 text-purple-500 font-14 leading-relaxed mt-5 rounded w-full"></p>
									)}

								{/* Effective data */}
								{payment.payment_method === 'E' && (
									<CashData
										isoCode={isoCode}
										payment={payment}
										percent_off={this.props.percent_off}
										calculateTotal={this.props.calculateTotal}
									/>
								)}

								{(payment.payment_method === 'S' ||
									payment.payment_method === 'B') && (
									<div className="w-full mx-2 mt-5">
										<span className="text-gray-800 text-base leading-relaxed">
											Enter your payments details
										</span>
										{
											// Stripe data step
											payment.payment_method === 'S' && (
												<div className="w-full mt-5">
													<CardForm
														name={`${payment?.first_name} ${payment?.last_name}`}
														handleSave={
															this.props.actions.handleStateAffiliation
														}
														payment={payment}
														// is_renewal={this.props.is_renewal}
														// calculateTotal={this.props.calculateTotal}
													/>
													<div className="w-full justify-end items-center">
														<img
															src={logoStripe}
															alt="logo stripe"
															className="logo-stripe mt-2"
														/>
													</div>
												</div>
											)
										}
									</div>
								)}

								{(payment.payment_method === 'Z' ||
									payment.payment_method === 'T' ||
									payment.payment_method === 'P') && (
									<div className="w-full mx-2 mt-5">
										<div className="text-gray-800 text-base leading-relaxed">
											Account Data
										</div>

										{
											// PayPal Data
											payment.payment_method === 'P' && paypalData()
										}

										{
											//     // Transfer Data
											payment.payment_method === 'T' && transferData()
										}

										{/* Disclaimer */}
										{disclaimer()}

										<VoucherInput
											payment={payment}
											handleInput={this.handleInput}
											handleInputFile={this.handleInputFile}
										/>
									</div>
								)}

								{this.props.paymentResponse && (
									<div className="text-pink-500 font-bold text-sm w-full text-center mt-4">
										{this.props.paymentResponse}
									</div>
								)}
							</div>
						</AccordionDetails>
					</Accordion>

					<div
						className="flex w-full mt-5 cursor-pointer"
						onClick={() => {
							this.setState({ dialog: true });
						}}
					>
						<FormControlLabel
							className="m-0 w-full"
							label={
								<span
									className={`text-gray-800 text-base ${
										payment.acceptTerms === true ? 'font-bold' : 'font-normal'
									}`}
								>
									I agree to the
									<span className="mx-1 font-bold">Terms and Conditions</span>
								</span>
							}
							control={
								<Checkbox
									name="acceptTerms"
									required={true}
									className={
										payment.acceptTerms === true ? 'checkBoxActive' : 'checkBox'
									}
									onClick={(e) =>
										this.handleCheckbox(
											e,
											payment.acceptTerms === true ? false : true
										)
									}
									checked={payment.acceptTerms}
									inputProps={{
										title: 'Check this box if you wish to continue'
									}}
								/>
							}
						/>
					</div>
					<div className="flex w-full">
						<FormControlLabel
							className="m-0 w-full"
							label={
								<span
									className={`text-gray-800 text-base ${
										payment.oath_statement === true
											? 'font-bold'
											: 'font-normal'
									}`}
								>
									I hereby certify that all of the information provided by me in
									this application is accurate
								</span>
							}
							control={
								<Checkbox
									name="oath_statement"
									required={true}
									className={
										payment.oath_statement === true
											? 'checkBoxActive'
											: 'checkBox'
									}
									onClick={(e) =>
										this.handleCheckbox(
											e,
											payment.oath_statement === true ? false : true
										)
									}
									checked={payment.oath_statement}
									inputProps={{
										title: 'Check this box if you wish to continue'
									}}
								/>
							}
						/>
					</div>
				</div>
				<TermsConditionsDialog
					open={this.state.dialog}
					handleClose={this.closeDialog}
					is_affiliation={true}
					fullWidth={true}
				/>
				{this.state.showConfirm && (
					<ConfirmDialog
						open={this.state.showConfirm}
						handleClose={() => this.setState({ showConfirm: false })}
						handleApproved={this.handleChangeCoupon}
						propsConfirm={[]}
						title="¿Estás seguro que deseas aplicar otro cupón?"
						info="Está acción eliminará el descuento del cupón existente."
					/>
				)}
				{
					<SnackBar
						openAlert={this.state.openAlert}
						messageAlert={this.state.messageAlert}
						handleClose={this.handleCloseAlert}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const titular = state.affiliation.get('titular');
	return {
		titular
	};
};

const mapDispatchToProps = (dispatch) => {
	return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);

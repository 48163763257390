import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

const sanitizer = DOMPurify.sanitize;

export const data = [
	{
		icon: '/images/indicators/indicators-img1.svg',
		text: '<strong>Médicos disponibles las 24h</strong> del día'
	},
	{
		icon: '/images/indicators/indicators-img2.svg',
		text: 'Acceso a <strong>más de 200 clínicas</strong> privadas en todo el país con el respaldo de Mercantil Seguros'
	},
	{
		icon: '/images/indicators/indicators-img5.svg',
		text: '<strong>Atención médica domiciliaria</strong>'
	},
	{
		icon: '/images/indicators/indicators-img4.svg',
		text: '<strong>Cobertura de hospitalización y cirugía de Mercantil Seguros,</strong> hasta USD 10,000 por patología'
	},
	{
		icon: '/images/indicators/ambulance.svg',
		text: 'Traslado de emergencia en <strong>ambulancias</strong>'
	},
	{
		icon: '/images/indicators/time.svg',
		text: '<strong>Contacto con un médico</strong> en menos de 20 segundos'
	}
];

export const Title = () => (
	<h2 className="text-purple-500 text-center font-poppins text-2xl md:text-4xl leading-snug font-semibold px-5 mb-14">
		¿Por qué contratar{' '}
		<span className="text-pink-500 font-poppins font-semibold">asistensi</span>?
	</h2>
);

export const PrimaryComponent = ({ item }) => (
	<div className={'px-5 flex justify-start items-center flex-col w-full'}>
		<div className="flex items-center justify-center p-5 bg-purple-80 rounded-full pointer-events-none select-none">
			<img src={item.icon} alt={item.text} className="h-12 w-12" />
		</div>
		<p
			className="my-3 mt-5 text-lg leading-relaxed text-center text-gray-800 pointer-events-none select-none"
			dangerouslySetInnerHTML={{ __html: sanitizer(item.text) + '.' }}
		/>
	</div>
);

export const SecondaryComponent = () => (
	<>
		<hr className="border-gray-500 md:border-purple-500 md:mx-12 lg:mx-20 mt-4" />
		<div className="font-poppins text-base leading-relaxed md:text-xl font-semibold text-center text-purple-500 py-4 md:pt-6 md:pb-0 flex flex-col md:flex-row justify-center pointer-events-none">
			<p className="my-1 md:my-0 font-poppins mx-6 pointer-events-none">
				Desde USD 47 al mes
			</p>
			<span className="hidden md:inline-block">|</span>
			<p className="my-1 md:my-0 font-poppins mx-6 pointer-events-none">
				Sin examen médico previo
			</p>
			<span className="hidden md:inline-block">|</span>
			<p className="my-1 md:my-0 font-poppins mx-6 pointer-events-none">
				Contratación inmediata
			</p>

			{/* responsive */}
			<p className="md:hidden flex justify-center my-1 md:my-2 text-purple-300 font-semibold font-poppins mx-6 pointer-events-none">
				Amparado por Mercantil Seguros
			</p>
		</div>
		<p className="md:flex hidden justify-center my-1 md:my-2 text-purple-300 font-semibold font-poppins mx-6 pointer-events-none -ml-3">
			Amparado por Mercantil Seguros
		</p>
		<hr className="border-gray-500 md:hidden" />
	</>
);

import React, { useState, useEffect } from 'react';

import Validations from '../utils/Validations';
import SelectSimpleOutlined from './SelectSimpleOutlined';

const Location = ({
	userData = {},
	type,
	beneficiaries,
	index,
	save,
	actions,
	handleSetAlert,
	states = [],
	is_profile
}) => {
	const /* A copy of the userData object. */
	user = { ...userData };

	const [municipalities, setMunicipalities] = useState([]);
	const [cities, setCities] = useState([]);

	useEffect(() => {
		getMunicipalities();
	}, [userData.state, states]);

	useEffect(() => {
		getCities();
	}, [userData.municipality, municipalities]);

	useEffect(() => {
		(async () => {
			if (user.state) {
				if (states.length === 0) await getStates();
				if (user.municipality) {
					await getMunicipalities();
					if (user.city) await getCities();
				}
			}
		})();
	}, []);

	/**
	 *  * @returns Coverage Provinces
	 */
	const getStates = async () => {
		await actions.availableStates();
		if (user.state !== 'default') {
			await getMunicipalities();
		}
	};

	/**
	 *
	 * @param {number} id
	 * @returns Coverage Cities
	 */
	const getMunicipalities = async () => {
		let response = null;
		const stateId =
			user.id_state || (await states.find((c) => c.name === user.state)?.code);
		if (stateId) response = await actions.availableMunicipalities(stateId);
		if (response?.success) {
			setMunicipalities(response.municipalities);
		} else {
			handleSetAlert({
				openAlert: response?.openAlert,
				messageAlert: response?.messageAlert
			});
		}
	};

	/**
	 *
	 * @param {number} id
	 * @returns Coverage Barangays
	 */
	const getCities = async () => {
		let response = null;
		const municipalityId =
			user.id_municipality ||
			(await municipalities.find((c) => c.name === user.municipality)?.code);
		if (municipalityId)
			response = await actions.availableCities(municipalityId);
		if (response?.success) {
			await setCities(response.cities);
		} else {
			handleSetAlert({
				openAlert: response?.openAlert,
				messageAlert: response?.messageAlert
			});
		}
	};

	const handleInput = async (e) => {
		const { name, value } = e.target;
		user[name] = value;
		if (name === 'state') {
			user.id_state = states.find((m) => m.name === value).code;
			if (user?.formErrors?.state) delete user.formErrors.state;
			user.municipality = 'default';
			user.id_municipality = '';
			user.city = 'default';
			user.id_city = '';
			setMunicipalities([]);
			setCities([]);
		}
		if (name === 'municipality') {
			if (user?.formErrors?.municipality) {
				delete user.formErrors.municipality;
			}
			user.municipality = value;
			user.id_municipality = municipalities.find((m) => m.name === value).code;
			user.city = 'default';
			user.id_city = '';
			setCities([]);
		} else if (name === 'city') {
			if (user?.formErrors?.city) {
				delete user.formErrors.city;
			}
			user.city = value;
			user.id_city = cities.find((m) => m.name === value).code;
		}
		saveData();
	};

	const handleBlur = (e) => {
		const { name, value } = e.target;
		const errors = Validations.validateLocation(name, value, user.formErrors);
		user.formErrors = { ...user.formErrors, ...errors };
		saveData();
	};

	/**
	 * Function to save in redux titular, beneficiaries
	 */
	const saveData = () => {
		if (type === 'titular') save('titular', { ...user });
		if (type === 'beneficiary') {
			let beneficiariesData = [...beneficiaries];
			beneficiariesData[index] = { ...user };
			save('beneficiaries', [...beneficiariesData]);
		}
	};

	return (
		<>
			<SelectSimpleOutlined
				name="state"
				label="Province"
				placeholder="Select Province"
				addClass={`wInputThree ${
					(userData.state === 'default' || is_profile) && 'default'
				}`}
				value={userData?.state}
				simpleList={states.map((s) => s.name)}
				arrKey="name"
				onChange={(e) => handleInput(e)}
				helperText={userData.formErrors?.state}
				errors={userData.formErrors?.state}
				required={true}
				disabled={is_profile || states?.length === 0}
				onBlur={handleBlur}
			/>

			<SelectSimpleOutlined
				name="municipality"
				label="City"
				placeholder="City"
				addClass={`wInputThree ${
					(userData.municipality === 'default' || is_profile) && 'default'
				}`}
				value={userData?.municipality}
				simpleList={municipalities.map((s) => s.name)}
				arrKey="name"
				onChange={(e) => handleInput(e)}
				helperText={userData.formErrors?.municipality}
				errors={userData.formErrors?.municipality}
				required={true}
				disabled={is_profile || municipalities?.length <= 0}
				onBlur={handleBlur}
			/>

			<SelectSimpleOutlined
				name="city"
				label="Barangay"
				placeholder="Select Barangay"
				addClass={`wInputThree ${
					(userData.city === 'default' || is_profile) && 'default'
				}`}
				value={userData?.city}
				simpleList={cities.map((s) => s.name)}
				arrKey="name"
				onChange={(e) => handleInput(e)}
				helperText={userData.formErrors?.city}
				errors={userData.formErrors?.city}
				required={true}
				disabled={is_profile || cities?.length === 0}
				onBlur={handleBlur}
			/>
		</>
	);
};

export default Location;

import React from 'react';

export const transferData = () => (
	<div className="w-full mt-2">
		{
			<>
				<div className="text-gray-800 text-base leading-relaxed mt-5">
					<span className="font-bold mr-1 text-pink-500">Philippines: </span>
				</div>
				<div className="text-gray-800 text-base leading-relaxed">
					<span className="font-bold mr-1">Bank name: </span> Unionbank of the
					Philippines
				</div>
				<div className="text-gray-800 text-base leading-relaxed">
					<span className="font-bold mr-1">Account holder name: </span>{' '}
					asistensi Philippines
				</div>
				<div className="text-gray-800 text-base leading-relaxed">
					<span className="font-bold mr-1">Account number: </span> 000560036292
				</div>

				<div className="text-gray-800 text-base leading-relaxed">
					<span className="font-bold mr-1">Swift: </span> UBPHPHMM
				</div>
				<div className="text-gray-800 text-base leading-relaxed">
					<span className="font-bold mr-1">Account email: </span>{' '}
					tahnee@asistensi.com; bjorn@asistensi.com
				</div>
			</>
		}
	</div>
);

import React, { useState } from 'react';

import LeadForm from './LeadForm';
import PhoneIcon from '@material-ui/icons/Phone';

const StickyCall = ({ border }) => {
	const [showForm, setShowForm] = useState(false);

	return (
		<>
			<LeadForm setShowForm={setShowForm} showForm={showForm} />
			<div
				className={`hidden hover:bg-pink-500 hover:text-white text-pink-500 bg-white border border-pink-500 rounded-2xl cursor-pointer h-12 right-1 p-auto py-4 items-center ${
					!showForm ? 'md:fixed md:flex' : ''
				} ${border ? 'border border-white' : ''}`}
				style={{ bottom: '8rem', zIndex: '11' }}
				onClick={() => setShowForm(true)}
			>
				<PhoneIcon className="text-base ml-2" />
				<span className="text-center font-bold p-3 pl-1">
					We contact you for free
				</span>
			</div>
		</>
	);
};

export default StickyCall;

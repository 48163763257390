import React, { useCallback } from 'react';
import FileWithIconInput from 'components/commons/FileWithIconInput';
import { TextField } from '@material-ui/core';

const VoucherInput = ({ payment, handleInput, handleInputFile }) => {
	const errorClass = useCallback(
		(type) => {
			const classes = {
				label: () => {
					if (!payment?.reference_number) return 'cssLabel';
					return payment?.formErrors.reference_number
						? 'cssLabelError'
						: 'cssLabelSuccess';
				},
				input: () => {
					if (!payment?.reference_number) return 'cssInput';
					return payment?.formErrors.reference_number
						? 'cssInputError'
						: 'cssInputSuccess';
				}
			};
			return classes[type]();
		},
		[payment.formErrors]
	);

	return (
		<div className="flex flex-wrap pt-2">
			<TextField
				name={'reference_number'}
				label="Receipt number"
				placeholder="Enter receipt number"
				type="text"
				helperText={payment.formErrors.reference_number}
				variant="outlined"
				error={true}
				className="wInputTwo"
				FormHelperTextProps={{
					classes: { root: 'cssHelperTextError' }
				}}
				InputLabelProps={{
					classes: {
						root: errorClass('label')
					},
					shrink: true
				}}
				InputProps={{
					classes: {
						notchedOutline: errorClass('input')
					}
				}}
				margin="normal"
				value={payment.reference_number}
				required={true}
				onChange={(event) => handleInput(event)}
			/>

			<FileWithIconInput
				name="payment_voucher"
				label="Payment recepit"
				required={true}
				value={payment.payment_voucher}
				placeholder="Select payment receipt"
				addClass="wInputTwo"
				changeClass={false}
				helperText={payment.formErrors.payment_voucher}
				onChange={(e) => handleInputFile(e)}
			/>
		</div>
	);
};

export default VoucherInput;

import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Link from 'next/link';
import { useRouter } from 'next/router';

const plansData = [
	/* {
		title: 'asistensi Free',
		content: ['2 online medical consultations'],
		priceFrom: '0 PHP',
		per: '',
		textColor: 'text-purple-500',
		backgroundColor: 'white',
		button: {
			text: 'Free',
			url: '/freemium/Register',
			as: '/join-asistensi-free'
		},
		otherAction: {
			text: 'see more',
			url: '/plans',
			as: '/plans',
			class: 'text-pink-500'
		}
	}, */
	{
		title: 'asistensi Prime',
		content: [
			'Unlimited online medical consultations',
			'In-clinic and At-home primary care',
			'Ambulance service',
			'Laboratory tests',
			'Medication pickup and delivery',
			// eslint-disable-next-line react/jsx-key
			<span className="font-poppins">
				Coverage for hospitalization and surgery (PHP{' '}
				<span className="font-bold font-poppins">80,000.00</span>)
			</span>
		],
		priceFrom: 'From PHP 866',
		per: 'per month',
		button: { text: 'Buy now', url: '/affiliation', as: '/start-registration' },
		backgroundColor: '#200844',
		otherAction: {
			text: 'see more',
			url: '/plans',
			as: '/plans',
			class: 'text-white'
		}
	},
	{
		title: 'asistensi Plus',
		content: [
			'Unlimited online medical consultations',
			'In-clinic and At-home primary care',
			'Ambulance service',
			'4 Laboratory tests',
			'Medication pickup and delivery',
			// eslint-disable-next-line react/jsx-key
			<span className="font-poppins">
				Coverage for hospitalization and surgery (PHP{' '}
				<span className="font-bold font-poppins">150,000.00</span>)
			</span>
		],
		priceFrom: 'For as low as PHP 1,029',
		per: 'per month',
		button: {
			text: 'Buy now',
			url: '/affiliation',
			as: '/start-registration'
		},
		backgroundColor: '#4D3969',
		otherAction: {
			text: 'see more',
			url: '/plans',
			as: '/plans',
			class: 'text-white'
		}
	},
	{
		title: 'asistensi Premium',
		content: [
			'Unlimited online medical consultations',
			'In-clinic and At-home primary care',
			'Ambulance service',
			'6 Laboratory tests',
			'Medication pickup and delivery',
			// eslint-disable-next-line react/jsx-key
			<span className="font-poppins">
				Coverage for hospitalization and surgery (PHP{' '}
				<span className="font-bold font-poppins">300,000.00</span>)
			</span>
		],
		priceFrom: 'For as low as PHP 1,462',
		per: 'per month',
		button: {
			text: 'Buy now',
			url: '/affiliation',
			as: '/start-registration'
		},
		backgroundColor:
			//   "bg-gradient-to-r from-orange-500 via-orange-600 to-pink-700",
			'linear-gradient(106deg, #f7553b 0%, #fa4343 53%, #ff2152)',
		otherAction: {
			text: 'see more',
			url: '/plans',
			as: '/plans',
			class: 'text-white'
		}
	}
];

const PlansCard = ({ goToHomeQuotation }) => {
	return (
		<>
			<style>
				{`
				.cards{
					grid-template-columns: repeat(1, 1fr) !important;
				}

				@media only screen and (min-width: 720px) and (max-width: 1360px) {
					.cards {
						grid-template-columns: repeat(2, 1fr) !important;
					}
				}

				@media only screen and (min-width: 1360px){
					.cards {
						grid-template-columns: repeat(4, 1fr) !important;
					}
				}
			`}
			</style>
			<div className="w-full my-6 md:my-20 ">
				<h2 className="px-0 sm:px-10 lg:px-32 text-purple-500 text-center font-poppins text-2xl md:text-4xl leading-snug font-semibold mb-8">
					An{' '}
					<span className="text-pink-500 font-poppins font-semibold">
						asistensi{' '}
					</span>
					plan just for you
				</h2>
				<div className="gap-y-6 lg:gap-x-4 grid grid-cols-1 lg:grid-cols-4 py-12 cards lg:px-16 xl:px-20">
					{plansData.map((plan, i) => (
						<Card {...plan} key={i} goToHomeQuotation={goToHomeQuotation} />
					))}
				</div>
			</div>
		</>
	);
};

const Card = (props) => {
	const router = useRouter();
	const textColor = props.textColor ? props.textColor : 'text-white';
	return (
		<div
			className={'px-8 py-12 border-3 rounded-2xl mx-auto max-w-xs min-h-850p'}
			style={{ background: props.backgroundColor }}
		>
			<p
				className={`font-poppins font-bold text-2xl ${textColor} text-center min-h-50p md:min-h-80p lg:min-h-0`}
			>
				{props.title}
			</p>
			<div className="pt-8 h-full flex flex-col justify-around">
				<div className="min-h-50p md:min-h-60  flex flex-col flex-start">
					{props.content.map((benefit, i) => (
						<div
							className={`w-full flex ${textColor} align-center py-2`}
							key={benefit.title}
						>
							<span className={`${textColor} w-3 mr-1`}>
								<CheckCircleIcon />
							</span>
							<span className="font-poppins text-base px-4">{benefit}</span>
						</div>
					))}
				</div>
				<div>
					<span
						className={`mt-10 w-full block font-poppins font-bold ${textColor} text-center text-xl`}
					>
						{props.priceFrom}
					</span>
					<span
						className={`w-full block font-poppins ${textColor} text-center text-lg min-h-27p`}
					>
						{props.per}
					</span>
				</div>
				<div>
					<Link href={props.button.url} as={props.button.as}>
						<button className="white-btn mx-auto mt-4">
							<span className="font-poppins font-bold text-lg">
								{props.button.text}
							</span>
						</button>
					</Link>
					<Link href={props.otherAction.url} as={props.otherAction.as}>
						<span
							className={`cursor-pointer block text-center font-poppins underline pb-4 md:pb-0 mt-6 mb-8 ${props.otherAction.class}`}
						>
							{props.otherAction.text}
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default PlansCard;

import React from 'react';

export const paypalData = () => (
	<div className="w-full mt-2">
		<div className="text-gray-800 text-base leading-relaxed">
			<span className="font-bold mr-1">Account Name: </span> Asistensi Services
			Usa LLC
		</div>
		<div className="text-gray-800 text-base leading-relaxed">
			<span className="font-bold mr-1">URL: </span> paypal.me/asistensipagos
		</div>
		<div className="text-gray-800 text-base leading-relaxed">
			<span className="font-bold mr-1">Email: </span> pagos@asistensi.com
		</div>
	</div>
);

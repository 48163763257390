const identities = {
	driver: 'Driver\'s License',
	pag: 'Pag-Ibig',
	passport: 'Passport',
	SSS: 'SSS',
	TIN: 'TIN',
	UMID: 'UMID',
	prcID: 'PRC ID',
	votersID: 'Voter\'s ID',
	ownersECard: 'OWWA e-card',
	postalID: 'Postal ID',
	seniorCitizenID: 'Senior Citizen ID'
};

const NATIONALITIES = Object.entries(identities).map(([_key, value]) => ({
	value: value,
	name: value
}));

export { NATIONALITIES };

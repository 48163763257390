import React from 'react';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const imgDefault = `${serverUrlImages}/icons/user-03-c.svg`;

export const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 3,
		partialVisibilityGutter: 30,
		slidesToSlide: 2
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 3,
		partialVisibilityGutter: 30,
		slidesToSlide: 2
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 2,
		partialVisibilityGutter: 30,
		slidesToSlide: 2
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 1,
		partialVisibilityGutter: 50
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 1,
		partialVisibilityGutter: 50
	}
};

export const data = [
	{
		avatar: imgDefault,
		name: 'María Eugenia Villasmil de Suárez',
		type: 'Suegra en Venezuela',
		testimony:
			'Excelentes, amables, saben tratar a personas de edad avanzada, algo que es muy importante, muy complacida por sus atenciones. Hagan extensivo mi agradecimiento al personal médico y paramédico'
	},
	{
		avatar: imgDefault,
		name: 'Sonia Maritza Macedo',
		type: 'Madre en República Dominicana',
		testimony:
			'Muy encantada con su excelente servicio, no cambien nunca, sigan adelante, CON USTEDES ME BASTA, bendiciones'
	},
	{
		avatar: imgDefault,
		name: 'María Salomé Ferraz de Silva',
		type: 'Madre en Venezuela',
		testimony: 'Son buenísimos, los he recomendado a todos mis familiares'
	}
];

export const Title = () => (
	<div className="w-full flex md:justify-center flex-col md:items-center relative px-5 mt-10">
		<p className="text-purple-500 font-semibold text-xl text-left sm:text-center mb-2">
			¿Qué dicen sobre nosotros?
		</p>
		<h2 className="text-purple-500 font-semibold font-poppins text-2xl md:text-4xl lg:text-5xl leading-snug text-left sm:text-center w-full z-10">
			Opiniones sobre{' '}
			<span className="font-poppins text-pink-500">asistensi</span>
		</h2>
		<p className="text-purple-500 font-semibold text-2xl text-left sm:text-center mb-4 mt-2 hidden sm:inline-block">
			¡98% de nuestros usuarios están satisfechos!
		</p>
	</div>
);

export const PrimaryComponent = ({ item }) => {
	return (
		<>
			<style global jsx>
				{`
					.dot-testimonials {
						bottom: 7% !important;
					}

					.dot-testimonials .react-multi-carousel-dot--active button {
						background: #200044 !important;
					}

					.dot-testimonials .react-multi-carousel-dot button {
						border: 0 !important;
						background: #e6ebf1;
						width: 8px !important;
						height: 8px !important;
						margin-right: 12px !important;
					}

					@media only screen and (min-width: 1280px) {
						.dot-testimonials {
							display: none;
						}
						#__next
							> div
							> div
							> div
							> div:nth-child(10)
							> div:nth-child(3)
							> div {
							padding-bottom: 3.5rem !important;
						}
					}
				`}
			</style>

			<div className="min-h-93 p-8 border rounded-xl border-gray-500 m-4 cursor-pointer flex flex-col hover:shadow-lg duration-300 ease-in-out transition-shadow">
				<div className="flex">
					<div className="flex flex-col">
						<p className="font-poppins text-2xl text-purple-80 font-semibold pointer-events-none select-none">
							{item.name}
						</p>
						<p className="font-poppins text-base text-gray-800 font-semibold mt-6 pointer-events-none select-none">
							{item.type}
						</p>
					</div>
				</div>
				<p className="flex mt-6 text-base text-gray-800 leading-relaxe pointer-events-none select-none">
					"{item.testimony}."
				</p>
			</div>
		</>
	);
};

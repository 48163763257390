export const _calculateAge = (birthday) => {
	const today = new Date();
	const birthDate = new Date(birthday);
	let age = today.getFullYear() - birthDate.getFullYear();
	const monthToday = today.getMonth() + 1;
	const monthBirthDate = birthDate.getMonth() + 1;
	const m = monthToday - monthBirthDate;
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age -= 1;
	}
	return age;
};

/* eslint-disable quotes */
import React, { useState, useEffect, useCallback } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { labelClasses, notchedOutlineClasses } from '../utils/inputClasses';

import {
	TextField,
	MenuItem,
	Radio,
	FormControlLabel,
	CircularProgress,
	FormControl,
	FormLabel,
	RadioGroup,
	Tooltip
} from '@material-ui/core';
import Validations from '../utils/Validations';
import { useRouter } from 'next/router';

import { SnackBar } from '../widgets';
import {
	TextInput,
	DateInput,
	SelectCurrency,
	RadioInput,
	SelectSimpleOutlined
} from 'components/commons';
import { isArray } from 'util';
import SelectInterval from '../commons/SelectInterval';
import { _calculateAge } from '../utils/CalculateAge';
import CellPhoneInput from '../commons/CellPhoneInput';
import BasicModal from '../BasicModal/BasicModal';
import ChildModalPhilHealth from './common/ChildModalPhilHealth';
import formatFloat from '../utils/formatFloat';
import Location from '../commons/Location';
import { NATIONALITIES } from '../utils/nationalities';

const BeneficiaryForm = (props) => {
	const {
		beneficiary = {},
		index,
		is_profile,
		isAlly,
		beneficiaries = [],
		isRenew,
		editMember,
		isoCode,
		interval,
		states,
		actions
	} = props;
	const router = useRouter();
	const isMobile = router?.asPath?.includes('/m');

	const [alert, setAlert] = useState({
		openAlert: false,
		messageAlert: ''
	});
	const [loading, setLoading] = useState(false);
	const [openModalPhilHealth, setOpenModalPhilHealth] = useState(false);
	const [nationalities, setNationalities] = useState(NATIONALITIES);

	const handleCloseModal = () => setOpenModalPhilHealth(false);

	/**
	 * Effect default values selects nationality and second_nationality
	 */
	useEffect(() => {
		const { beneficiaries, index } = props;
		const { nationality = '' } = beneficiaries[index];

		const nationalitiesTmp = NATIONALITIES.filter(
			(nat) => nat.value !== nationality
		);
		setNationalities(nationalitiesTmp);
	}, [props.beneficiaries]);

	useEffect(() => {
		const getInitialData = async () => {
			const {
				stepIndex,
				beneficiaries,
				index,
				saveState,
				beneficiary,
				currentPlan,
				setCurrentPlan,
				is_profile,
				isRenew
			} = props;
			const beneficiariesData = [...beneficiaries];
			//Quotation Flow - Get Age
			let age = beneficiary.age;
			if (beneficiary.birth_date && !beneficiary.age) {
				age = await _calculateAge(beneficiary.birth_date);
				beneficiariesData[index].age = age;
			}

			if (
				!is_profile &&
				(!isNaN(parseInt(beneficiary.age)) || !isNaN(parseInt(age))) &&
				(!beneficiary.products ||
					beneficiary.products?.length <= 0 ||
					!beneficiary.products[0]?.intervals)
			) {
				const result = await props.getProductsByFrequency({
					age: beneficiary.age || age
				});
				if (result.products) {
					beneficiariesData[index].products = result.products;
				}
			}

			if (!is_profile && beneficiary.product_id) {
				const productType =
					beneficiary.product_type &&
					typeof beneficiary.product_type === 'string'
						? JSON.parse(beneficiary.product_type)
						: beneficiary.product_type;
				const findProduct = beneficiary?.products?.find(
					(product) => product._id === beneficiary.product_id
				);
				beneficiariesData[index].product_type = productType?.intervals
					? productType
					: findProduct;

				if (!currentPlan[index] && isRenew)
					setCurrentPlan(index, beneficiary.product_id);
			}
			saveState('beneficiaries', beneficiariesData);
		};
		getInitialData();
	}, []);

	const handleClose = () => setAlert({ ...alert, openAlert: false });

	const handleInput = async (e, index) => {
		let { name, value } = e.target;

		const beneficiariesData = [...props.beneficiaries];

		if (name === 'nationality') {
			const nationalitiesTmp = NATIONALITIES.filter(
				(nat) => nat.value !== value
			);
			setNationalities(nationalitiesTmp);
		}

		beneficiariesData[index][name] =
			name === 'phil_health' ? JSON.parse(value) : value;

		props.saveState('beneficiaries', [...beneficiariesData]);

		if (name === 'phil_health') {
			cleanPhilHealth(index, JSON.parse(value));
		}
	};

	const handleBlur = (e, index) => {
		const { name, value } = e.target;
		const beneficiariesData = [...props.beneficiaries];

		if (value === '' || !value) return;

		Validations.validateFieldBeneficiaries(
			beneficiariesData,
			props.saveState,
			name,
			value,
			index
		);
	};

	const handleBlurBithDate = (e, index) => {
		const { value } = e.target;
		const beneficiariesData = [...props.beneficiaries];

		if (value === '' || !value) return;

		Validations.validateFieldBeneficiaries(
			[...beneficiariesData],
			props.saveState,
			'birth_date',
			value,
			index
		);
	};

	const handleInputSex = async (event, index) => {
		let { name, value } = event.target;
		const beneficiariesData = [...props.beneficiaries];
		beneficiariesData[index][name] = value;
		props.saveState('beneficiaries', [...beneficiariesData]);
		Validations.validateFieldBeneficiaries(
			beneficiaries,
			props.saveState,
			name,
			value,
			index
		);
	};

	const handlePhone = (value, name, index) => {
		const beneficiariesData = [...props.beneficiaries];
		beneficiariesData[index][name] = value;
		props.saveState('beneficiaries', [...beneficiariesData]);
		Validations.validateFieldBeneficiaries(
			beneficiariesData,
			props.saveState,
			name,
			value,
			index
		);
	};

	const handleSelectPlan = async (product, index) => {
		const beneficiariesData = [...props.beneficiaries];
		beneficiariesData[index].product_type = product;
		beneficiariesData[index].product_id = product._id;
		beneficiariesData[index].product_price = product.product_price;
		beneficiariesData[index].total = product.product_price;
		props.saveState('beneficiaries', [...beneficiariesData]);
	};

	const handleBirthDate = async (date, index) => {
		setLoading(true);
		let beneficiariesData = [...props.beneficiaries];
		beneficiariesData[index].birth_date = date;
		if (date && date instanceof Date && !isNaN(date.valueOf())) {
			const age = _calculateAge(date);
			beneficiariesData[index].age = age;
			const result = await props.getProductsByFrequency({ age });
			if (result.products) {
				beneficiariesData[index].products = result.products;
				if (beneficiaries[index].product_id) {
					const productType = result.products.find(
						(p) => beneficiaries[index].product_id === p._id
					);
					handleSelectPlan(productType, index);
				}
			}
			if (result.success === false) {
				beneficiariesData[index].products = [];
				beneficiariesData[index].product_id = '';
				beneficiariesData[index].product_type = {};
				beneficiariesData[index].product_price = 0;
				setAlert({
					openAlert: result?.openAlert,
					messageAlert: result?.messageAlert
				});
			}
		}
		props.saveState('beneficiaries', beneficiariesData);
		setLoading(false);
	};

	/**
	 * @param {Number} index
	 * @param {Boolean} value
	 */
	const cleanPhilHealth = useCallback(
		(index, value) => {
			const { beneficiaries } = props;

			if (!value) setOpenModalPhilHealth(true);

			beneficiaries.map((ben, i) => {
				if (i === index) {
					if (value) {
						ben.phil_health_number = '';

						if (ben?.formErrors?.second_dni) delete ben?.formErrors?.second_dni;
						if (ben?.formErrors?.second_nationality)
							delete ben?.formErrors?.second_nationality;
					} else {
						ben.second_nationality = 'default';
						ben.second_dni = '';
						ben.phil_health_number = '';

						if (ben?.formErrors?.phil_health_number) {
							delete ben.formErrors?.phil_health_number;
						}
					}
				}

				return ben;
			});
		},
		[props]
	);

	return (
		<div className="form-group w-full" key={index}>
			<div className="flex flex-wrap beneficiary-form">
				<TextInput
					name="first_name"
					label="First Name"
					placeholder="Enter first name"
					helperText={beneficiary?.formErrors?.first_name}
					errors={beneficiary?.formErrors?.first_name}
					value={beneficiary?.first_name}
					addClass="wInputThree"
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={true}
					FormHelperTextProps={{
						classes: { root: is_profile ? '' : 'cssHelperTextError' }
					}}
					disabled={beneficiary?.NotEditCustomer}
				/>

				<TextInput
					name="middle_name"
					label="Middle Name"
					placeholder="Enter middle name"
					helperText={beneficiary?.formErrors?.middle_name}
					errors={beneficiary?.formErrors?.middle_name}
					value={beneficiary?.middle_name}
					addClass="wInputThree"
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={false}
					FormHelperTextProps={{
						classes: { root: is_profile ? '' : 'cssHelperTextError' }
					}}
					disabled={beneficiary?.NotEditCustomer}
				/>

				<TextInput
					name="last_name"
					label="Last Name"
					placeholder="Enter last name"
					helperText={beneficiary?.formErrors?.last_name}
					errors={beneficiary?.formErrors?.last_name}
					value={beneficiary?.last_name}
					addClass="wInputThree"
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={true}
					FormHelperTextProps={{
						classes: { root: is_profile ? '' : 'cssHelperTextError' }
					}}
					disabled={beneficiary?.NotEditCustomer}
				/>

				<DateInput
					clearable={true}
					name="birth_date"
					label="Date of Birth"
					placeholder="mm/dd/yyyy"
					maxDate={new Date()}
					helperText={beneficiary?.formErrors?.birth_date}
					errors={beneficiary?.formErrors?.birth_date}
					value={beneficiary?.birth_date}
					addClass="wInputThree"
					onChange={(date) => handleBirthDate(date, index)}
					onBlur={(e) => handleBlurBithDate(e, index)}
					disabled={is_profile || isRenew || beneficiary?.NotEditCustomer}
				/>

				<TextField
					select
					label="Gender"
					name="sex"
					value={beneficiary?.sex}
					onChange={(event) => handleInputSex(event, index)}
					onBlur={(e) => handleBlur(e, index)}
					variant="outlined"
					error={true}
					required={true}
					className={`field-responsive wInputThree m-t25 ${
						beneficiary?.sex === 'default' && 'default'
					}`}
					FormHelperTextProps={{
						classes: { root: is_profile ? '' : 'cssHelperTextError' }
					}}
					InputLabelProps={{
						classes: {
							root: labelClasses({
								value: beneficiary?.sex,
								errors: beneficiary?.formErrors?.sex
							})
						},
						shrink: true
					}}
					InputProps={{
						classes: {
							notchedOutline: notchedOutlineClasses({
								value: beneficiary?.sex,
								errors: beneficiary?.formErrors?.sex
							})
						}
					}}
					SelectProps={{
						IconComponent: KeyboardArrowDownIcon
					}}
					helperText={beneficiary.formErrors?.sex}
					margin="normal"
					disabled={beneficiary.NotEditCustomer}
				>
					<MenuItem value="default" hidden disabled>
						Select gender
					</MenuItem>
					<MenuItem value="F">Female</MenuItem>
					<MenuItem value="M">Male</MenuItem>
				</TextField>

				{!is_profile && (
					<TextField
						select
						label="Civil Status"
						name="civil_status"
						value={beneficiary.civil_status}
						onChange={(e) => handleInput(e, index)}
						onBlur={(e) => handleBlur(e, index)}
						variant="outlined"
						error={true}
						required={true}
						className={`field-responsive wInputThree m-t25 ${
							beneficiary.civil_status === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.civil_status || beneficiary.civil_status === 'default') ? 'cssLabel' : (beneficiary.formErrors?.civil_status) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.civil_status &&
									!beneficiary.formErrors?.civil_status
										? 'cssLabel'
										: (!beneficiary.civil_status &&
												beneficiary.formErrors?.civil_status) ||
										  beneficiary.formErrors?.civil_status
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.civil_status || beneficiary.civil_status === 'default') ? 'cssInput' : (beneficiary.formErrors?.civil_status) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.civil_status &&
									!beneficiary.formErrors?.civil_status
										? 'cssInput'
										: (!beneficiary.civil_status &&
												beneficiary.formErrors?.civil_status) ||
										  beneficiary.formErrors?.civil_status
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						helperText={beneficiary.formErrors?.civil_status}
						margin="normal"
						disabled={beneficiary.NotEditCustomer}
					>
						<MenuItem value="default" hidden disabled>
							Select civil status
						</MenuItem>
						<MenuItem value="Single">Single</MenuItem>
						<MenuItem value="Married">Married</MenuItem>
						<MenuItem value="Annulled">Annulled</MenuItem>
						<MenuItem value="Separated">Separated</MenuItem>
					</TextField>
				)}

				{!(beneficiary.kinship === 'TITULAR' && is_profile) && (
					<TextField
						select
						label="Relationship"
						name="kinship"
						value={beneficiary.kinship}
						onChange={(e) => handleInput(e, index)}
						onBlur={(e) => handleBlur(e, index)}
						variant="outlined"
						error={true}
						required={true}
						className={`wInputThree ${
							beneficiary.kinship === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								// root: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssLabel' : (beneficiary.formErrors?.kinship) ? 'cssLabelError' : 'cssLabelSuccess',
								root:
									beneficiary.kinship && !beneficiary.formErrors?.kinship
										? 'cssLabel'
										: (!beneficiary.kinship &&
												beneficiary.formErrors?.kinship) ||
										  beneficiary.formErrors?.kinship
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								// notchedOutline: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssInput' : (beneficiary.formErrors?.kinship) ? 'cssInputError': 'cssInputSuccess'
								notchedOutline:
									!beneficiary.kinship && !beneficiary.formErrors?.kinship
										? 'cssInput'
										: (!beneficiary.kinship &&
												beneficiary.formErrors?.kinship) ||
										  beneficiary.formErrors?.kinship
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						helperText={beneficiary.formErrors?.kinship}
						margin="normal"
						// disabled={is_profile ? true : false}
						disabled={
							(is_profile && !editMember) || beneficiary.NotEditCustomer
						}
					>
						<MenuItem value="default" hidden disabled>
							Select relationship
						</MenuItem>
						<MenuItem value="Child">Child</MenuItem>

						{(beneficiaries.length === 1 ||
							beneficiary.kinship === 'Spouse') && (
							<MenuItem value="Spouse">Spouse</MenuItem>
						)}
						{(!beneficiaries.find((b) => b.kinship === 'Parent') ||
							beneficiary.kinship === 'Parent') && (
							<MenuItem value="Parent">Parent</MenuItem>
						)}

						<MenuItem value="Sibling">Sibling</MenuItem>
						<MenuItem value="Grandparent">Grandparent</MenuItem>
						{(beneficiary.kinship !== '' || beneficiary.kinship === 'Other') &&
							beneficiary.kinship !== 'Child' &&
							beneficiary.kinship !== 'Spouse' &&
							beneficiary.kinship !== 'Parent' &&
							beneficiary.kinship !== 'Sibling' &&
							beneficiary.kinship !== 'Grandparent' &&
							beneficiary.kinship !== 'default' && (
								<MenuItem
									value={
										beneficiary.kinship === 'Other'
											? beneficiary.other_kinship
											: beneficiary.kinship
									}
								>
									{beneficiary.kinship === 'Other'
										? beneficiary.other_kinship
										: beneficiary.kinship}
								</MenuItem>
							)}
						<MenuItem value="Other">Other</MenuItem>
					</TextField>
				)}

				{beneficiary.kinship === 'Other' && (
					<TextInput
						name="other_kinship"
						label="Specify relationship"
						placeholder="Relationship"
						helperText={beneficiary.formErrors?.other_kinship}
						errors={beneficiary.formErrors?.other_kinship}
						value={beneficiary.other_kinship}
						addClass="wInputThree"
						onChange={(e) => handleInput(e, index)}
						onBlur={(e) => handleBlur(e, index)}
						required={isAlly ? false : true}
						FormHelperTextProps={{
							classes: {
								root: is_profile ? '' : 'cssHelperTextError'
							}
						}}
						disabled={is_profile || isRenew || beneficiary.NotEditCustomer}
					/>
				)}

				{/* Identifier... */}
				<SelectSimpleOutlined
					name="nationality"
					label="Type of ID Number"
					value={beneficiary.nationality}
					placeholder="Select ID type"
					listItems={NATIONALITIES}
					addClass={`wInputThree ${
						(beneficiary.nationality === 'default' ||
							is_profile ||
							isRenew ||
							beneficiary.NotEditCustomer) &&
						'default'
					}`}
					helperText={beneficiary.formErrors?.nationality}
					errors={beneficiary.formErrors?.nationality}
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={true}
					FormHelperTextProps={{
						classes: {
							root: is_profile ? '' : 'cssHelperTextError'
						}
					}}
					disabled={is_profile || isRenew || beneficiary.NotEditCustomer}
				/>

				<TextInput
					name="dni"
					label="ID Number"
					placeholder="Enter ID number"
					helperText={beneficiary.formErrors?.dni}
					errors={beneficiary.formErrors?.dni}
					value={beneficiary.dni}
					addClass="wInputThree"
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={isAlly ? false : true}
					disabled={is_profile || isRenew || beneficiary.NotEditCustomer}
					FormHelperTextProps={{
						classes: {
							root: is_profile || isRenew ? '' : 'cssHelperTextError'
						}
					}}
				/>

				{!is_profile && (
					<div className="wInputThree groupRadio">
						<Tooltip
							title="Note: When changing, the data entered will be deleted."
							placement="top"
							arrow
						>
							<FormControl component="fieldset">
								<FormLabel component="legend" className="mb-2">
									PhilHealth Member
								</FormLabel>
								<RadioGroup
									row
									aria-label="phil_health"
									name="phil_health"
									value={beneficiary.phil_health}
									onChange={(e) => handleInput(e, index)}
									required={false}
								>
									<FormControlLabel
										value={true}
										control={<RadioInput />}
										label="Yes"
										className="mr-10"
									/>
									<FormControlLabel
										value={false}
										control={<RadioInput />}
										label="No"
									/>
								</RadioGroup>
							</FormControl>
						</Tooltip>
					</div>
				)}

				{beneficiary?.phil_health && !is_profile ? (
					<>
						<TextInput
							name="phil_health_number"
							label="PhilHealth Number"
							placeholder="PhilHealth Number"
							helperText={beneficiary.formErrors?.phil_health_number}
							errors={beneficiary.formErrors?.phil_health_number}
							value={beneficiary.phil_health_number}
							addClass="wInputThree"
							onChange={(e) => handleInput(e, index)}
							onBlur={(e) => handleBlur(e, index)}
							required={beneficiary.phil_health}
							disabled={beneficiary.NotEditCustomer}
							FormHelperTextProps={{
								classes: {
									root: is_profile || isRenew ? '' : 'cssHelperTextError'
								}
							}}
						/>
						<div className="wInputThree" />
					</>
				) : (
					!is_profile && (
						<>
							<SelectSimpleOutlined
								name="second_nationality"
								label="Type of Second ID Number"
								value={beneficiary.second_nationality}
								placeholder="Select second ID type"
								listItems={nationalities}
								addClass={`wInputThree ${
									beneficiary.second_nationality === 'default' && 'default'
								}`}
								helperText={beneficiary.formErrors?.second_nationality}
								errors={beneficiary.formErrors?.second_nationality}
								onChange={(e) => handleInput(e, index)}
								onBlur={(e) => handleBlur(e, index)}
								required={!beneficiary.phil_health}
								FormHelperTextProps={{
									classes: {
										root: is_profile ? '' : 'cssHelperTextError'
									}
								}}
								disabled={beneficiary.NotEditCustomer}
							/>

							<TextInput
								name="second_dni"
								label="ID Number"
								placeholder="Enter ID number"
								helperText={beneficiary.formErrors?.second_dni}
								errors={beneficiary.formErrors?.second_dni}
								value={beneficiary.second_dni}
								addClass="wInputThree"
								onChange={(e) => handleInput(e, index)}
								onBlur={(e) => handleBlur(e, index)}
								required={!beneficiary.phil_health}
								disabled={is_profile || isRenew || beneficiary.NotEditCustomer}
								FormHelperTextProps={{
									classes: {
										root: is_profile || isRenew ? '' : 'cssHelperTextError'
									}
								}}
							/>
						</>
					)
				)}

				<TextInput
					name="email"
					label="Email Address"
					placeholder="Enter email address"
					helperText={beneficiary.formErrors?.email}
					errors={beneficiary.formErrors?.email}
					value={beneficiary.email}
					addClass="wInputThree"
					onChange={(e) => handleInput(e, index)}
					onBlur={(e) => handleBlur(e, index)}
					required={isAlly ? false : true}
					FormHelperTextProps={{
						classes: { root: is_profile ? '' : 'cssHelperTextError' }
					}}
					disabled={beneficiary.NotEditCustomer}
				/>

				<CellPhoneInput
					label="Mobile number"
					addClass="wInputThree"
					value={beneficiary.phone_one}
					onChange={(telNumber) => handlePhone(telNumber, 'phone_one', index)}
					error={beneficiary.formErrors?.phone_one}
					required={true}
					disabled={beneficiary.NotEditCustomer}
				/>

				<div
					className={`${
						beneficiary.kinship !== 'Other' ? 'wInputThree' : 'hidden'
					}`}
				/>
				{!is_profile && (
					<>
						<TextField
							select
							name="country"
							label="Country"
							placeholder="Country"
							helperText={beneficiary.formErrors?.country}
							variant="outlined"
							error={true}
							className={`field-responsive wInputThree m-t25 ${
								beneficiary.country === 'default' && 'default'
							}`}
							FormHelperTextProps={{
								classes: { root: is_profile ? '' : 'cssHelperTextError' }
							}}
							InputLabelProps={{
								classes: {
									// root: (!beneficiary.country || beneficiary.country === 'default') ? 'cssLabel' : (beneficiary.formErrors?.country) ? 'cssLabelError' : 'cssLabelSuccess',
									root:
										beneficiary.country && !beneficiary.formErrors?.country
											? 'cssLabel'
											: (!beneficiary.country &&
													beneficiary.formErrors?.country) ||
											  beneficiary.formErrors?.country
											? 'cssLabelError'
											: 'cssLabelSuccess'
								},
								shrink: true
							}}
							InputProps={{
								classes: {
									// notchedOutline: (!beneficiary.country || beneficiary.country === 'default') ? 'cssInput' : (beneficiary.formErrors?.country) ? 'cssInputError': 'cssInputSuccess'
									notchedOutline:
										!beneficiary.country && !beneficiary.formErrors?.country
											? 'cssInput'
											: (!beneficiary.country &&
													beneficiary.formErrors?.country) ||
											  beneficiary.formErrors?.country
											? 'cssInputError'
											: 'cssInputSuccess'
								}
							}}
							SelectProps={{
								IconComponent: KeyboardArrowDownIcon
							}}
							margin="normal"
							value={beneficiary.country}
							required={isAlly ? false : true}
							onChange={(e) => handleInput(e, index)}
							onBlur={(e) => handleBlur(e, index)}
							// disabled={is_profile ? true : false}
							disabled={
								(is_profile && !editMember) || beneficiary.NotEditCustomer
							}
						>
							<MenuItem value="default" hidden disabled>
								Select the country
							</MenuItem>
							{['Philippines'].map((country) => (
								<MenuItem key={country} value={country}>
									{country}
								</MenuItem>
							))}
						</TextField>

						<Location
							userData={props.beneficiary}
							beneficiaries={beneficiaries}
							index={index}
							save={props.saveState}
							handleSetAlert={setAlert}
							type="beneficiary"
							states={states}
							actions={actions}
						/>

						<TextInput
							name="address"
							label="Home Address"
							placeholder="Enter home adress"
							helperText={beneficiary.formErrors?.address}
							errors={beneficiary.formErrors?.address}
							value={beneficiary.address}
							addClass="wInputThree"
							onChange={(e) => handleInput(e, index)}
							onBlur={(e) => handleBlur(e, index)}
							required={isAlly ? false : true}
							FormHelperTextProps={{
								classes: { root: is_profile ? '' : 'cssHelperTextError' }
							}}
							disabled={beneficiary.NotEditCustomer}
						/>
					</>
				)}

				{is_profile !== true && (
					<>
						<div className="w-full mt-4 mx-2 hidden md:block">
							<table
								className="border-gray-300 border bg-white w-full"
								cellPadding="10"
								cellSpacing="0"
							>
								<tbody>
									<tr className="h-16">
										<td>
											<span className="text-gray-800 text-base font-bold leading-relaxed pl-2">
												Select Plan
											</span>
										</td>
										{isArray(beneficiary.products) &&
											beneficiary.birth_date &&
											beneficiary.products.map((product, i) => (
												<td key={i}>
													<FormControlLabel
														name="product"
														value={String(product._id)}
														control={
															<Radio
																value={String(product._id)}
																className={
																	beneficiary.product_id === product._id
																		? 'checkBoxActive'
																		: 'checkBox'
																}
																checked={
																	beneficiary.product_id === product._id
																		? true
																		: false
																}
															/>
														}
														onChange={() => handleSelectPlan(product, index)}
														label={
															<span
																className={`text-gray-800 text-normal ${
																	beneficiary.product_id === product._id
																		? 'font-bold'
																		: 'font-normal'
																}`}
															>
																{product.name}
															</span>
														}
														labelPlacement="end"
														disabled={beneficiary.NotEditCustomer}
													/>
												</td>
											))}
										{loading && (
											<td align="start">
												<CircularProgress
													className="text-gray-300 m-1"
													size={24}
													thickness={3}
												/>
											</td>
										)}
										{!beneficiary.birth_date && (
											<td className="text-gray-600 text-normal">
												You must enter your date of birth
											</td>
										)}
										<td className="w-48">
											<span className="text-normal leading-relaxed">
												{beneficiary?.product_type &&
												beneficiary?.product_type.intervals ? (
													<span
														className={`text-gray-800 ${
															isMobile ? 'text-xs' : 'text-base'
														}`}
													>
														{isoCode && isoCode.toUpperCase()}.
														{formatFloat(
															beneficiary?.product_type?.intervals[interval][
																isoCode
															]
														)}
													</span>
												) : (
													<span className="text-gray-600">
														{isoCode && isoCode.toUpperCase()}.0
													</span>
												)}
											</span>
											<span className="ml-1">
												<SelectInterval />
											</span>
										</td>
										<td align="end">
											<span className="pr-2">
												<SelectCurrency hideFlag={true} />
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</>
				)}
				{/* RESPONSIVE PLANS */}
				{is_profile !== true && (
					<div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
						<div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
							<span className="text-gray-800 text-normal font-bold">
								Select Plan
							</span>
						</div>
						<div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
							<span className="font-bold">Plan</span>
							<div className="flex flex-col items-end">
								{isArray(beneficiary.products) &&
									beneficiary.products.map((product, i) => (
										<span key={i} className="m-b5">
											<FormControlLabel
												key={i}
												name="product"
												value={String(product._id)}
												control={
													<Radio
														value={String(product._id)}
														className={
															beneficiary.product_id === product._id
																? 'checkBoxActive'
																: 'checkBox'
														}
														checked={
															beneficiary.product_id === product._id
																? true
																: false
														}
													/>
												}
												onChange={() => handleSelectPlan(product, index)}
												label={
													<span
														className={`text-gray-800 text-normal ${
															beneficiary.product_id === product._id
																? 'font-bold'
																: 'font-normal'
														}`}
													>
														{product.name}
													</span>
												}
												labelPlacement="start"
												disabled={beneficiary.NotEditCustomer}
											/>
										</span>
									))}
							</div>
						</div>
						<div className="text-gray-800 text-normal flex items-center justify-end px-5 min-h-50p">
							<span>
								{beneficiary?.product_type &&
								beneficiary?.product_type.intervals ? (
									<span
										className={`text-gray-800 ${
											isMobile ? 'text-xs' : 'text-base'
										}`}
									>
										{isoCode && isoCode.toUpperCase()}.
										{formatFloat(
											beneficiary?.product_type?.intervals[interval][isoCode]
										)}
									</span>
								) : (
									<span
										className={`text-gray-600 ${isMobile ? 'text-xs' : ''}`}
									>
										{isoCode && isoCode.toUpperCase()}.0
									</span>
								)}
								<span className="ml-3">
									<SelectInterval />
								</span>
								<span className="ml-3">
									<SelectCurrency hideFlag={true} />
								</span>
							</span>
						</div>
					</div>
				)}
			</div>
			<SnackBar
				openAlert={alert.openAlert}
				messageAlert={alert.messageAlert}
				handleClose={handleClose}
			/>

			<BasicModal open={openModalPhilHealth} onClose={handleCloseModal}>
				<ChildModalPhilHealth onClose={handleCloseModal} />
			</BasicModal>
		</div>
	);
};

export default BeneficiaryForm;

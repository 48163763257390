import React from 'react';

export const data = [
	{
		icon: '/images/partners/maxicare.svg',
		text: 'Logo Maxicare',
		name: 'logo_maxicare.svg',
		styles: {
			width: 210
		}
	},
	{
		icon: '/images/partners/hi-precision.svg',
		text: 'Logo Hi-Precision',
		name: 'logo_hi_precision.svg',
		styles: {
			width: 210
		},
		class: ''
	},
	{
		icon: '/images/partners/argonix.svg',
		text: 'Logo Argonix',
		name: 'logo_argonix.svg',
		styles: {
			width: 60
		},
		class: 'icon-width'
	},
	{
		icon: '/images/partners/generika.svg',
		text: 'Logo Generika Drugstore',
		name: 'logo_generika.svg'
		// styles:{
		// 	width: 210,
		// }
	},
	{
		icon: '/images/partners/lifeline.svg',
		text: 'Logo Lifeline',
		name: 'logo_lifeline.svg',
		styles: {
			width: 60
		},
		class: 'icon-width'
	}
];

export const Title = () => (
	<h2 className="text-2xl md:text-4xl leading-snug text-center text-purple-500 font-poppins font-semibold mb-4 md:mb-10">
		Our partners
	</h2>
);

export const PrimaryComponent = ({ item }) => (
	<>
		<style>
			{`
                #__next > div > div > div > div:nth-child(11) > div > div > ul {
                    align-items: center !important;
                }

                #__next > div > div > div > div:nth-child(10) > div:nth-child(3) > div {
                    padding-bottom: 5rem;
                }

                @media only screen and (min-width: 960px) and (max-width: 1279px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
                @media only screen and (min-width: 1280px) and (max-width: 1520px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
				@media only screen and (max-width: 1200px) {
					.icon-width {
					  width:50px !important;
					}
				  }

				  }

            `}
		</style>
		<div className="flex px-5 justify-center items-center flex-col w-full mb-8">
			<div className="flex items-center justify-center pointer-events-none h-24">
				<img
					name={item.name}
					src={item.icon}
					alt={item.text}
					style={item.styles}
					className={`object-cover ${item.class}`}
				/>
			</div>
		</div>
	</>
);

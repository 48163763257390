export const clientStatus = (status) => {
	if (status === 'active') {
		return 'Activo';
	} else if (status === 'requireMedicalVerification') {
		return 'Pendiente por verificación médica';
	} else if (status === 'pending') {
		return 'Pendiente por aprobar pago';
	} else if (status === 'annulled') {
		return 'Anulado';
	} else if (status === 'free_trial') {
		return 'Prueba gratuita';
	} else if (status === 'to_renew') {
		return 'Por renovar';
	} else if (status === 'suspended') {
		return 'Suspendido';
	} else {
		return '';
	}
};

export const chipClientStatus = (status) => {
	return (
		<>
			{status === 'active' && (
				<span className="text-xs bg-green-100 text-green-200 py-2 px-3 rounded-full">
					{clientStatus(status, true)}
				</span>
			)}
			{(status === 'pending' ||
				status === 'to_renew' ||
				status === 'requireMedicalVerification') && (
				<span className="text-xs bg-pink-200 text-pink-500 py-2 px-3 rounded-full">
					{clientStatus(status, true)}
				</span>
			)}
			{status === 'free_trial' && (
				<span className="text-xs bg-purple-200 text-purple-500 py-2 px-3 rounded-full">
					{clientStatus(status, true)}
				</span>
			)}
			{(status === 'suspended' || status === 'annulled') && (
				<span className="text-xs bg-gray-300 text-gray-800 py-2 px-3 rounded-full">
					{clientStatus(status, true)}
				</span>
			)}
		</>
	);
};

import React from 'react';
import PropTypes from 'prop-types';

const StickyButton = ({ className, isSticky, onClick }) => {
	return (
		<a
			onClick={onClick}
			className={`${className} primary-button text-2xl h-20`}
			style={{
				display: isSticky ? 'flex' : 'none',
				bottom: 0,
				width: '100%',
				borderTopColor: 'white',
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				zIndex: 20
			}}
		>
			Quote a plan
		</a>
	);
};

StickyButton.prototype = {
	onClick: PropTypes.func,
	isSticky: PropTypes.bool,
	className: PropTypes.string
};

export default StickyButton;

import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextField,
	InputAdornment,
	Button
} from '@material-ui/core';
import { isBoolean } from 'util';

const HealthInfo = (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			fullScreen={props.fullScreen}
			fullWidth={true}
			maxWidth="sm"
			className="dialog-container"
		>
			<DialogTitle>
				<div className="font-18 color-purple normal strong m-t20">
					Ok, por favor respondenos si:
				</div>
			</DialogTitle>
			<DialogContent>
				<div className="fw flex flex-col">
					{props.titular.questions.map((q, i) => (
						<div key={i} className="question-box">
							<h4 className="color-purple nm sm-small">{q.name}</h4>
							{q.help && (
								<p className="lh-18 text-justify sm-small">{q.help}</p>
							)}
							{!isBoolean(
								props.titular.answers[i] && props.titular.answers[i].value
							) && (
								<span className="fw color-red x-small">
									Debe seleccionar una opción
								</span>
							)}

							{isBoolean(
								props.titular.answers[i] && props.titular.answers[i].value
							) &&
								props.titular.answers[i].value !==
									props.titular.answers[i].validValue && (
									<span className="fw color-green x-small lh-12 strong">
										¡Podrás continuar con la afiliación del servicio SIN la
										cobertura de atención emergencias médicas en clínicas!
									</span>
								)}

							<RadioGroup
								aria-label={q.name}
								name={`answers`}
								value={String(props.titular.answers[i].value)}
								className={`check-responsive sm-small checkBox flex-center flex-row jc-b`}
								onChange={(e) => props.handleRadioInputQuestions(e, i)}
								required={true}
							>
								<FormControlLabel
									value="true"
									control={<Radio value={true} className="checkBox" />}
									label={q.yes || 'Si'}
									labelPlacement="end"
									className="wInputTwo"
								/>
								<FormControlLabel
									value="false"
									control={<Radio value={false} className="checkBox" />}
									label={q.not || 'No'}
									labelPlacement="end"
									className="wInputTwo"
								/>
							</RadioGroup>
						</div>
					))}
					<div className="flex flex-col">
						<TextField
							name={`heigth`}
							label="¿Cuánto mides?"
							type="number"
							helperText={props.titular.formErrors.heigth}
							variant="outlined"
							className="field-responsive wInputFull m-t20"
							InputLabelProps={{
								classes: {
									root: !props.titular.heigth
										? 'cssLabel'
										: props.titular.formErrors.heigth
										? 'cssLabelError'
										: 'cssLabelSuccess',
									focused: !props.titular.heigth
										? 'cssLabel'
										: props.titular.formErrors.heigth
										? 'cssLabelError'
										: 'cssLabelSuccess'
								},
								shrink: true
							}}
							InputProps={{
								classes: {
									root: !props.titular.heigth
										? 'cssInput'
										: props.titular.formErrors.heigth
										? 'cssInputError'
										: 'cssInputSuccess',
									notchedOutline: !props.titular.heigth
										? 'cssInput'
										: props.titular.formErrors.heigth
										? 'cssInputError'
										: 'cssInputSuccess'
								},
								startAdornment: (
									<InputAdornment position="start">m</InputAdornment>
								),
								inputProps: { min: 0, max: 3, step: 0.01 }
							}}
							margin="normal"
							required={true}
							value={props.titular.heigth}
							onChange={props.handleInput}
						/>

						<TextField
							name={`weight`}
							label="¿Cuánto pesas?"
							type="number"
							helperText={props.titular.formErrors.weight}
							variant="outlined"
							className="field-responsive wInputFull m-t20"
							InputLabelProps={{
								classes: {
									root: !props.titular.weight
										? 'cssLabel'
										: props.titular.formErrors.weight
										? 'cssLabelError'
										: 'cssLabelSuccess',
									focused: !props.titular.weight
										? 'cssLabel'
										: props.titular.formErrors.weight
										? 'cssLabelError'
										: 'cssLabelSuccess'
								},
								shrink: true
							}}
							InputProps={{
								classes: {
									root: !props.titular.weight
										? 'cssInput'
										: props.titular.formErrors.weight
										? 'cssInputError'
										: 'cssInputSuccess',
									notchedOutline: !props.titular.weight
										? 'cssInput'
										: props.titular.formErrors.weight
										? 'cssInputError'
										: 'cssInputSuccess'
								},
								startAdornment: (
									<InputAdornment position="start">Kg</InputAdornment>
								),
								inputProps: { max: 300 }
							}}
							margin="normal"
							required={true}
							value={props.titular.weight}
							onChange={props.handleInput}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					className="primaryButton p-5-34 m-l5"
					onClick={props.includeTitular}
				>
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

HealthInfo.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(HealthInfo);

import React from 'react';
import Link from 'next/link';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;






const banner1 = `${serverUrlImages}/PH/home/online-doctor-philippines-r.jpg`;
const banner2 = `${serverUrlImages}/PH/home/in-clinic-and-at-home.jpg`;
const banner3 = `${serverUrlImages}/PH/home/insurance-coverage.jpg`;
const banner4 = `${serverUrlImages}/PH/home/ambulance-transfers-2.jpg`;
const banner5 = `${serverUrlImages}/PH/home/laboratory-tests.jpg`;
const banner6 = `${serverUrlImages}/PH/home/medication-pickup.jpg`;

const sanitizer = DOMPurify.sanitize;
const TextAsistensi =
		'<span class="text-pink-500 font-bold">asistensi</span>';

export const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 3
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 3
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 2
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 2
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 1
	}
};

export const data_one = [
	{
		img: {
			src: banner1,
			alt: 'Unlimited telemedicine',
			name: 'medico-cabecera-virtual-mexico.jpg'
		},
		title: 'Unlimited telemedicine',
		subtitle: `<span>Our general doctors are available on-demand, 24 hours, via telephone or videocall on our ${TextAsistensi} App - available in iOS and Android</span>`
	},
	{
		img: {
			src: banner2,
			alt: 'Unlimited home care consultations',
			name: 'lectura-examenes-medicos.png'
		},
		title: 'In-clinic and at-home primary care consultations',
		subtitle: (`
			<span>
			Whenever required, our doctors and paramedics are available to determine our members' medical needs in person as well.
			</span>
		`)
	},
	{
		img: {
			src: banner3,
			alt: 'Insurance coverage for hospitalization for medical emergencies caused by accidents and illnesses',
			name: 'servicios-ambulancias.jpg'
		},
		title: 'Insurance coverage for hospitalization for medical emergencies caused by accidents and illnesses',
		subtitle: (`
			<span>
			With access to the network of private hospitals and healthcare facilities affiliated with Maxicare. 
			Includes medical expenses coverage up to PHP 300,000.00, per year, which is enough to cover over 90% of the most common emergencies.
			</span>
			`)
	}
];

export const data_two = [
	{
		img: {
			src: banner4,
			alt: 'Ambulance transfers',
			name: 'medico-cabecera-virtual-venezuela.jpg'
		},
		title: 'Ambulance transfers',
		subtitle: (`
			<span>
			In the event of a medical emergency, our doctors will coordinate transfers to the hospital in ambulances equipped with advanced life support.
			</span>
			`)
	},
	{
		img: {
			src: banner5,
			alt: 'Laboratory tests',
			name: 'img-laboratorio.png'
		},
		title: 'Laboratory tests',
		subtitle: `
			<span>
			We offer 2 laboratory tests per year, as long as they are prescribed by our doctors and are included in the plan. Click <a href="faq/?q=18" class="cursor-pointer font-poppins font-bold" target="_blank"> here</a>.
			</span>
			`
	},
	{
		img: {
			src: banner6,
			alt: 'Medication pickup and delivery',
			name: 'servicios-ambulancias.jpg'
		},
		title: 'Medication pickup and delivery',
		subtitle: `<span>Includes the pick up or delivery of medication, as long as it is prescribed by our doctors and is included in the plan.</span>`
	}
];

export const Title = () => (
	<h2 className="mt-5 text-purple-500 text-center font-semibold text-2xl md:text-4xl leading-snug mb-4 font-poppins">
		Our services
	</h2>
);

export const PrimaryComponent = ({ item, index, lastItem }) => (
	<>
		<style global jsx>
			{`
				.transform-scale:hover .scale-children {
					transform: scale(1) !important;
					animation-name: fadeIn !important;
					animation-fill-mode: both !important;
					-webkit-animation-duration: 1s !important;
					animation-duration: 1s !important;
					-webkit-animation-fill-mode: both !important;
				}
				.min-size-arrow {
					min-width: 24px;
					min-height: 24px;
				}
				.react-multiple-carousel__arrow {
					z-index: 10 !important;
					background-color: #fff !important;
				}
			`}
		</style>
		<div className="my-4 mx-3 relative transition duration-300 ease-in-out min-h-93 rounded mr-3">
			<img
				src={item.img?.src}
				className="rounded-xl w-full"
				name={item.img?.name}
				alt={item.img?.alt}
			/>
			<p className="text-base sm:text-lg font-bold leading-snug text-gray-800 mt-4 text-center h-18 md:h-14 font-poppins">
				{item.title}
			</p>
			<div>
				<p className="text-base m:text-base text-gray-800 leading-relaxed mt-4 lg:mt-6 xl:mt-1 text-center font-poppins" dangerouslySetInnerHTML={{__html: sanitizer(item.subtitle)}}></p>
			</div>
		</div>
	</>
);

export const SecondaryComponent = () => (
	<div className="flex w-full justify-center mt-3 mb-0">
		<Link href="/benefits" as="/health-services">
			<button className="white-btn">Ver más servicios de salud</button>
		</Link>
	</div>
);

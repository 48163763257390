import React, { useRef, useState, useEffect } from 'react';

const UseSticky = () => {
	const [isSticky, setIsSticky] = useState(false);
	const element = useRef(null);
	const cotizaElement = useRef(null);

	const handleScroll = () => {
		if (element.current && cotizaElement.current) {
			setIsSticky(
				element.current.getBoundingClientRect().bottom < 155 &&
					(cotizaElement.current.getBoundingClientRect().top >= 155 ||
						cotizaElement.current.getBoundingClientRect().bottom < 155)
			);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

	return { isSticky, element, cotizaElement };
};

export default UseSticky;

import React from 'react';

export const disclaimer = () => (
	<>
		<div className="bg-purple-200 rounded p-3 my-3">
			<span className="text-sm text-purple-500">
				In order to validate your payment more quickly and effectively, please
				validate the payment with the first name, last name and ID number of the
				policyholder when making the transaction on the Bank&apos;s platform.
			</span>
		</div>

		<div className="bg-pink-200 text-pink-500 text-sm leading-relaxed p-5 rounded my-4">
			<div className="text-xl font-bold">Remember!</div>
			<p>
				The waiting period starts as soon as payments made via Paypal and bank
				transfer are confirmed! You will receive a notification when we confirm
				your payment.
			</p>
		</div>
	</>
);

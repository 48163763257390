import React, { Component } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import BeneficiaryForm from './BeneficiaryForm';

class BeneficiariesContainer extends Component {
	state = { expanded: 0 };

	handleChange = (panel) => (value) => {
		this.setState({
			expanded: value && this.state.expanded !== panel ? panel : false
		});
	};

	render() {
		const {
			saveState,
			calculations,
			removeBeneficiary,
			isRenew,
			updatePlan,
			showQuestions,
			isAdminOrAlly,
			currentPlan,
			setCurrentPlan,
			is_ally_client,
			getProductsByFrequency,
			isoCode,
			interval,
			intervals
		} = this.props;
		const beneficiaries = isRenew
			? this.props.beneficiaries
			: this.props.beneficiaries?.filter(
					(benefict) => benefict?.kinship !== 'TITULAR'
			  );
		const { expanded } = this.state;

		return (
			<div className="w-full">
				<style global jsx>
					{`
						.mt-m-1 {
							margin-top: -2px;
						}
						.delete::before {
							height: 0px;
						}
					`}
				</style>
				{beneficiaries?.map((beneficiary, index) => (
					<Accordion
						key={index}
						className={
							'border border-gray-300 shadow-none rounded-none mt-m-1 delete'
						}
						expanded={expanded === index}
						onChange={this.handleChange(index)}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<div className="w-full flex items-center justify-between">
								<span className="text-base font-bold text-gray-800 leading-relaxed">
									{isRenew ? (
										<b>
											{beneficiary?.first_name} {beneficiary?.last_name}
										</b>
									) : (
										<b>Member {index + 1}</b>
									)}
								</span>
								{isRenew ? (
									<button
										className="mr-2 text-small tracking-extra text-pink-500 font-bold font-poppins uppercase"
										onClick={() => removeBeneficiary(index, beneficiary?._id)}
									>
										Excluded member
									</button>
								) : (
									!beneficiary?.NotEditCustomer && (
										<button
											className="mr-2"
											onClick={() => removeBeneficiary(index)}
										>
											<DeleteIcon className="text-gray-600" />
										</button>
									)
								)}
							</div>
						</AccordionSummary>
						<AccordionDetails className="bg-gray-100 border-t">
							<BeneficiaryForm
								saveState={saveState}
								calculations={calculations}
								beneficiaries={beneficiaries}
								// getPlansByAge={getPlansByAge}
								beneficiary={beneficiary}
								index={index}
								isAlly={this.props.isAlly}
								fetchAddons={this.props.fetchAddons}
								stepIndex={this.props.stepIndex}
								isRenew={isRenew}
								updatePlan={showQuestions ? updatePlan : true}
								showQuestions={showQuestions}
								currentPlan={currentPlan || {}}
								setCurrentPlan={setCurrentPlan}
								isAdminOrAlly={isAdminOrAlly}
								is_ally_client={is_ally_client}
								getProductsByFrequency={getProductsByFrequency}
								isoCode={isoCode}
								intervals={intervals}
								interval={interval}
								states={this.props.states}
								actions={this.props.actions}
							/>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		);
	}
}

export default BeneficiariesContainer;

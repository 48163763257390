import React, { useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { LinearProgressBar } from 'components/widgets';
import LayoutContainer from 'components/layout/LayoutContainer';
import StickyButton from 'components/home/StickyButton';
import useSticky from 'components/hooks/useSticky';
import { FastQuotation } from 'components/forms';
import StickyCall from 'components/home/StickyCall';
// carousels children
import {
	data as indicatorData,
	Title as IndocatorTitle,
	PrimaryComponent as IndicatorComp,
	SecondaryComponent as SecIndicatorComp
} from 'components/home/childrensCarousel/indicator';
import {
	data as newsData,
	PrimaryComponent as NewsComp
} from 'components/home/childrensCarousel/news';
import {
	responsive as benefitsResponsive,
	data_one as benefitsDataOne,
	data_two as benefitsDataTwo,
	Title as BenefitTitle,
	PrimaryComponent as BenefitComp
	// SecondaryComponent as SecBenefitComp,
} from 'components/home/childrensCarousel/benefits';

import {
	responsive as testimonialsResponsive,
	data as testimonialsData,
	Title as TestimonialTitle,
	PrimaryComponent as TestimonialComp
} from 'components/home/childrensCarousel/testimonials';
import {
	data as partnersData,
	Title as PartnerTitle,
	PrimaryComponent as PartnerComp
} from 'components/home/childrensCarousel/partners';
import HireComponent from '../src/components/home/HireComponent';
import PlansCard from '../src/components/plans/PlansCard';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const MainHeader = dynamic(
	() => import('components/home/mainHeader/MainHeader'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);
const Promo = dynamic(() => import('components/home/Promo'), {
	loading: () => <LinearProgressBar showLogo={false} />
});
const CarouselContainer = dynamic(
	() => import('components/home/CarouselContainer'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);
const ContainerVideo = dynamic(
	() => import('components/widgets/ContainerVideo'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);

const ServicesCarousel = dynamic(
	() => import('components/home/ServicesCarousel'),
	{ loading: () => <LinearProgressBar showLogo={false} /> }
);

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const imgPh = `${serverUrlImages}/PH/home/what-is-asistensi-phillippines.jpg`;

const COUNTRY_NAME = process.env.REACT_APP_COUNTRY_CODE;
const FACEBOOK_PAGEID = process.env.REACT_FACEBOOK_PAGE_ID;

const HomePage = () => {
	const router = useRouter();
	const canonicalURL =
		process.env.REACT_APP_DOMAIN_URL + (router?.asPath || router?.pathname);

	const { isSticky, element, cotizaElement } = useSticky();
	const [showVideo, setShowVideo] = useState(false);
	const [slide, setSlide] = useState(0);
	const [showButton, setShowButton] = useState(true);

	const promoElement = useRef(null);

	const handleVideo = (showV) => {
		setShowVideo(showV);
	};

	const goToHomeQuotation = () => {
		let dims =
			cotizaElement.current.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo(0, dims - 100);
		const event_quotation = {
			dataLayer: {
				event: 'evento-boton-home-quiero-cotizar'
			}
		};
		TagManager.dataLayer(event_quotation);
	};

	useEffect(() => {
		const tagManagerArgs = {
			dataLayer: {
				event: 'pageview',
				pageTitle: `asistensi ${COUNTRY_NAME} | Seguros de emergencias médicas`
			}
		};
		TagManager.dataLayer(tagManagerArgs);

		if (location?.pathname) {
			if (router.asPath !== '/') {
				const { nick_promoter, coupon } = router.query;
				let coupon_promoter = '';
				if (nick_promoter) {
					if (coupon) coupon_promoter = coupon;
					const inCookie = Cookies.get('influencer');
					if (!inCookie)
						Cookies.set(
							'influencer',
							JSON.stringify({ nick_promoter, coupon_promoter }),
							{ expires: 90 }
						);
				}
			}
		}
	}, []);

	return (
		<LayoutContainer>
			<style>
				{`
            .transform-text {
              transform: rotate(-90deg);
              left: -30px;
              bottom: 30%;
            }
            @media only screen and (min-width: 600px) and (max-width: 959px) {
              .transform-text {
                transform: none;
              }
            }
            @media only screen and (max-width: 599px) {
              .transform-text {
                transform: none;
              }
            }
            `}
			</style>
			<Head>
				<link rel="canonical" href={canonicalURL} />
			</Head>
			<div className="bg-gray-100 relative overflow-hidden">
				{showButton && (
					<StickyButton
						className="fixed md:hidden"
						isSticky={isSticky}
						onClick={goToHomeQuotation}
					/>
				)}
				<StickyCall />
				<MainHeader element={element} goToHomeQuotation={goToHomeQuotation} />
			</div>

			<HireComponent />

			<ServicesCarousel />

			<div
				id="cotizacion"
				className="bg-white container pt-4 md:pt-20 pb-20"
				ref={cotizaElement}
			>
				<FastQuotation origin="Cotización" fromHome={true} />
			</div>

			<div className="pt-4 pb-20 lg:py-20 container flex flex-col relative md:flex-row md:items-start">
				<div className="w-full h-full flex flex-col md:order-2 md:w-1/2 md:ml-24 md:self-stretch">
					<h2 className="text-2xl md:text-3xl lg:text-4xl text-center md:text-left text-purple-500 font-poppins font-semibold">
						What is{' '}
						<span className="text-pink-500 font-poppins font-semibold">
							asistensi{' '}
						</span>
						?
					</h2>
					<div className="md:flex-grow flex flex-col justify-around mt-4">
						<p className="text-gray-750 font-poppins leading-relaxed text-base xl:text-xl">
							<span className="text-pink-500 font-poppins font-bold">
								asistensi
							</span>{' '}
							is the only global online health and medical emergency insurance
							that allows you{' '}
							<span className="font-bold font-poppins">
								to continue protecting your family&apos;s health in the
								Philippines from anywhere in the world.
							</span>
						</p>
						<p className="text-gray-750 font-poppins leading-relaxed text-base xl:text-xl mt-4">
							We are the first global digital healthcare company focused on
							supporting families who have been affected by migration. Our
							services and plans are designed to guarantee{' '}
							<span className="font-bold font-poppins">
								medical emergency care with immediate assistance 24 hours a day,
								365 days a year.
							</span>
						</p>
					</div>
				</div>

				<div className="rounded-2xl flex justify-center items-center w-full h-full relative overflow-hidden mt-16 md:mt-0 md:w-1/2">
					<div className="w-full h-auto">
						<img
							loading="lazy"
							name="asistensi-phillipines.png"
							alt="What is asistensi Phillipines?"
							src={imgPh}
							className="w-full"
						/>
					</div>
					{/* <div className="cursor-pointer absolute bg-white p-5 rounded-full">
						<PlayArrowIcon
							className="text-pink-500 sm-icon"
							onClick={() => handleVideo(true)}
						/>
					</div> */}
				</div>

				{/* {showVideo === true && (
					<ContainerVideo
						src="https://player.vimeo.com/video/565030462"
						handleClose={handleVideo}
					/>
				)} */}
			</div>

			<PlansCard />

			{/* partners carousel */}
			<CarouselContainer
				data={partnersData}
				Title={PartnerTitle}
				PrimaryComponent={PartnerComp}
				isCenter
				isContainer={false}
			/>

			<MessengerCustomerChat pageId={FACEBOOK_PAGEID} />
		</LayoutContainer>
	);
};

export default HomePage;

import React from 'react';
import PropTypes from 'prop-types';

const ChildModalPhilHealth = ({ onClose }) => {
	return (
		<div className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex justify-center items-center px-4">
			<div className="my-14 bg-white p-5 flex flex-col w-full overflow-auto h-full max-h-22-rem sm:max-w-2xl sm:p-8 rounded-2xl relative">
				<span className="mt-4 font-poppins text-purple-500 font-bold text-2xl leading-snug text-center mb-4 px-12">
					If you do not have a PhilHealth number, do not worry
				</span>
				<span className="flex justify-center text-gray-800 text-lg mb-1 text-center md:px-6">
					You can still avail of our plans with a minimal cost of PHP 2,400 that
					will be applied upon checkout.
				</span>

				<div className="flex justify-center mt-12">
					<button
						className="primary-button ml-2 text-lg font-bold"
						onClick={onClose}
					>
						Understood
					</button>
				</div>
			</div>
		</div>
	);
};

ChildModalPhilHealth.propTypes = {
	onClose: PropTypes.func.isRequired
};

export default ChildModalPhilHealth;

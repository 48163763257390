/* eslint-disable quotes */
import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
	TextField,
	MenuItem,
	Radio,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Tooltip,
	CircularProgress,
	FormControl,
	FormLabel,
	RadioGroup
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import CountryList from '../utils/CountryList';
import { SnackBar } from '../widgets';
import Validations from '../utils/Validations';
import FileWithIconInput from '../commons/FileWithIconInput';
import {
	TextInput,
	SelectSimpleOutlined,
	DateInput,
	SelectCurrency,
	RadioInput
} from 'components/commons';
import SelectInterval from '../commons/SelectInterval';
import { _calculateAge } from '../utils/CalculateAge';
import CellPhoneInput from '../commons/CellPhoneInput';
import BasicModal from '../BasicModal/BasicModal';
import ChildModalPhilHealth from './common/ChildModalPhilHealth';
import formatFloat from '../utils/formatFloat';
import Location from '../commons/Location';
import { NATIONALITIES } from '../utils/nationalities';

const COUNTRY_NAME = process.env.REACT_APP_COUNTRY_CODE;

const SEXS = [
	{
		value: 'M',
		name: 'Male'
	},
	{
		value: 'F',
		name: 'Female'
	}
];

const MARITAL_STATUS = [
	{ value: 'Single', name: 'Single' },
	{ value: 'Married', name: 'Married' },
	{ value: 'Annulled', name: 'Annulled' },
	{ value: 'Separated', name: 'Separated' }
];

const HeadlineForm = (props) => {
	const { twoColumns = false } = props;
	const router = useRouter();

	const [alert, setAlert] = useState({
		openAlert: false,
		messageAlert: ''
	});

	const [isChangeEmail, setIsChangedEmail] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showInfo, setShowInfo] = useState('');
	const [activePhilHealth, setActivePhilHealth] = useState(true);
	const [openModalPhilHealth, setOpenModalPhilHealth] = useState(false);
	const [nationalities, setNationalities] = useState(NATIONALITIES);
	const isMobile = router?.asPath?.includes('/m');
	const isAllyUser = props.isAllyMaster || props.isAllyMatrix || props.isAlly;

	const handleClose = () => setAlert({ ...alert, openAlert: false });

	/**
	 * Effect default values selects nationality and second_nationality
	 */
	useEffect(() => {
		const {
			titular: { nationality = '' }
		} = props;
		const nationalitiesTmp = NATIONALITIES.filter(
			(nat) => nat.value !== nationality
		);
		setNationalities(nationalitiesTmp);
	}, [props.titular]);

	useEffect(() => {
		const getInitialData = async () => {
			const {
				saveState,
				readOnlyPlans,
				is_profile,
				getProductsByFrequency,
				titular
			} = props;
			let titularData = { ...titular };

			if (!titularData.formErrors) {
				if (
					(titular.include_titular === false || !titularData.include_titular) &&
					!titularData.city
				)
					titularData.city = '';
				titularData.formErrors = {};
				saveState('titular', titularData);
			}
			if (
				titularData?.include_titular &&
				!readOnlyPlans &&
				titularData.age &&
				(!titularData.products || titularData.products?.length <= 0) &&
				is_profile !== true
			) {
				const result = await getProductsByFrequency({
					age: titular.age
				});
				if (result.products) titularData.products = result.products;
				titular.kinship = 'TITULAR';
				saveState('titular', titularData);
			}

			if (titularData.product_id) {
				const productType =
					titularData.product_type &&
					typeof titularData.product_type === 'string'
						? JSON.parse(titularData.product_type)
						: titularData.product_type;
				if (titular?.products?.length > 0) {
					const findProduct = titular?.products?.find(
						(product) => product._id === productType._id
					);
					titularData.product_type = productType;
					titularData.questions = findProduct?.questions;
				}

				saveState('titular', titularData);
			}

			setShowInfo('');
		};
		getInitialData();
	}, []);

	/* Setting the titular._id to the userID in localStorage. */
	useEffect(() => {
		if (!isAllyUser || props.is_ally_client) {
			const { titular, saveState } = props;
			titular._id = titular._id || localStorage.getItem('userID');
			saveState('titular', titular);
		}
	}, []);

	// Get Products when titular has birth_date
	useEffect(() => {
		let titularData = { ...props.titular };
		const getProducts = async () => {
			const age = _calculateAge(props.titular.birth_date);
			titularData.age = age;

			const result = await props.getProductsByFrequency({ age });
			if (result.products) titularData.products = result.products;
			props.saveState('titular', titularData);
		};
		if (
			props.titular.include_titular &&
			props.titular.birth_date &&
			(!props.titular.products || props.titular.products.length === 0)
		) {
			getProducts();
		}
	}, [props.titular.include_titular]);

	/**
	 * It saves the state of the form
	 * @param event - the event that triggered the function
	 */
	const handleInput = async (event) => {
		const {
			is_profile,
			saveState,
			readOnlyPlans,
			titular,
			paymentHeadlineForm,
			payment,
			useTitularData
		} = props;
		const { name, value } = event.target;

		if (name === 'email') {
			setIsChangedEmail(props.originalEmail !== titular.email ? true : false);
		}

		if (name === 'dni' && paymentHeadlineForm && useTitularData) {
			payment.dni = value;
			saveState('payment', payment);
		}
		if (name === 'city' && paymentHeadlineForm && useTitularData) {
			payment.city = value;
			saveState('payment', payment);
		}

		if (name === 'nationality') {
			const nationalitiesTmp = NATIONALITIES.filter(
				(nat) => nat.value !== value
			);
			setNationalities(nationalitiesTmp);
			titular.dni = '';
		}

		if (name === 'phil_health') {
			cleanPhilHealth(JSON.parse(value));
			setShowInfo('');
			return;
		}

		saveState('titular', {
			...props.titular,
			[name]: name === 'phil_health' ? JSON.parse(value) : value
		});
		setShowInfo('');
	};

	/**
	 * It validates the field and saves the state
	 * @param e - the event object
	 * @returns The function handleBlur is being returned.
	 */
	const handleBlur = (e) => {
		const { name, value } = e.target;
		const { saveState } = props;

		if (value === '' || !value) return;

		Validations.validateFieldHeadLine(
			{ ...props.titular, [name]: value },
			saveState,
			name,
			value
		);
	};

	/**
	 * It handles the input file.
	 * @param event - The event that triggered the function.
	 * @returns The reader.readAsDataURL(file) is being returned.
	 */
	const handleInputFile = (event) => {
		const { saveState } = props;
		const { name, files } = event.target;
		const file = files[0];
		const data = {
			body: '',
			type: file.type,
			extension: file.name.split('.').pop()
		};
		const reader = new FileReader();
		reader.onloadend = function () {
			data.body = reader.result;
		};
		saveState('titular', { ...props.titular, [name]: file, doc_dni: data });
		setShowInfo('');
		Validations.validateFieldHeadLine(
			{ ...props.titular, [name]: file, doc_dni: data },
			saveState,
			'doc_dni',
			file
		);
		return reader.readAsDataURL(file);
	};

	/**
	 * It handles the input of the country field in the form
	 * @param e - The event object
	 */
	const handleInputCountry = (e) => {
		const { titular, saveState, payment, paymentHeadlineForm, useTitularData } =
			props;
		const { name, value } = e.target;

		if (payment && paymentHeadlineForm && useTitularData) {
			payment.country = value;
			payment.zip_zone = '';
			saveState('payment', payment);
		}

		saveState('titular', {
			...titular,
			[name]: value,
			city: '',
			state: '',
			address: ''
		});
		setShowInfo('');
	};

	const handleBlurCountry = (e) => {
		const { name, value } = e.target;
		const { titular, saveState } = props;

		Validations.validateFieldHeadLine(
			{ ...titular, [name]: value, city: '', address: '' },
			saveState,
			name,
			value
		);
	};

	/**
	 * It takes a value and a name, and then it sets the state of the titular object to the value of the
	 * name
	 * @param value - The value of the input
	 * @param name - The name of the field.
	 */
	const handlePhone = (value, name) => {
		const { payment, saveState, titular, paymentHeadlineForm, useTitularData } =
			props;

		saveState('titular', { ...titular, [name]: value });
		setShowInfo('');

		if (paymentHeadlineForm && useTitularData) {
			payment[name] = value;
			saveState('payment', payment);
		}
	};

	/**
	 * onBlur method handleBlurPhone
	 *
	 * @param {*} e // event
	 * @param {string} country_code
	 */
	const handleBlurPhone = (e, country_code = '') => {
		const { value } = e.target;

		Validations.validateFieldHeadLine(
			{ ...props.titular, phone_one: value },
			props.saveState,
			'phone_one',
			value
		);
	};

	/**
	 * It takes an event, and then it takes the name and value of the event target, and then it saves the
	 * state of the titular, and then it sets the showInfo to an empty string, and then it validates the
	 * field headline
	 * @param event - The event that triggered the function.
	 */
	const handleInputSex = async (event) => {
		let { name, value } = event.target;
		props.saveState('titular', {
			...props.titular,
			[name]: value,
			conditions_pregnant: false
		});
		setShowInfo('');
		Validations.validateFieldHeadLine(
			{ ...props.titular, [name]: value, conditions_pregnant: false },
			props.saveState,
			name,
			value
		);
	};

	const handleCheckbox = (event, param) => {
		const { name, checked } = event.target;
		props.saveState('titular', {
			...props.titular,
			[name]: name === 'promo_code' ? param : checked
		});
		setShowInfo('');
		Validations.validateFieldHeadLine(
			{ ...props.titular, [name]: name === 'promo_code' ? param : checked },
			props.saveState,
			name,
			checked
		);
	};

	const handleCheckboxAssistedSelling = (event, param) => {
		const { name, checked } = event.target;
		props.saveState('titular', {
			...props.titular,
			[name]: name === 'assisted_selling' ? param : checked
		});
		setShowInfo('');
		Validations.validateFieldHeadLine(
			{
				...props.titular,
				[name]: name === 'assisted_selling' ? param : checked
			},
			props.saveState,
			name,
			checked
		);
	};

	/**
	 * handle birth date
	 *
	 * @param {Date} date
	 */
	const handleBirthDate = async (date) => {
		setLoading(true);
		const {
			saveState,
			is_profile,
			readOnlyPlans,
			getProductsByFrequency,
			titular
		} = props;
		let titularData = { ...titular };
		titularData.birth_date = date;

		if (date && date instanceof Date && !isNaN(date.valueOf())) {
			if (titular?.formErrors?.birth_date)
				delete titular?.formErrors?.birth_date; //Mutate State Directly to delete the error ?
			const age = _calculateAge(date);
			titularData.age = age;
			if (titular.include_titular && is_profile !== true && !readOnlyPlans) {
				const result = await getProductsByFrequency({ age });
				if (result.products) {
					titularData.products = result.products;
				}
				if (result.products && titular.product_id) {
					const productType = result.products.find(
						(p) => titular.product_id === p._id
					);
					titularData.product_id = productType._id;
					titularData.product_type = productType;
					titularData.product_price = productType.product_price;
					titularData.total = productType.product_price;
				}
				if (result.success === false) {
					titularData.products = [];
					titularData.product_id = '';
					titularData.product_type = {};
					titularData.product_price = 0;
					titularData.total = 0;
					setAlert({
						openAlert: result.openAlert,
						messageAlert: result.messageAlert
					});
				}
				saveState('titular', titularData);
				setShowInfo('');
			}
		}
		Validations.validateFieldHeadLine(
			titularData,
			saveState,
			'birth_date',
			date
		);
		setLoading(false);
	};

	/**
	 * It takes a product object as an argument, and then sets the state of the titular object to the
	 * product object
	 * @param product - The product object that was selected
	 */
	const handleSelectPlan = async (product) => {
		let titularData = { ...props.titular };
		titularData.product_id = product._id;
		titularData.product_type = product;
		titularData.product_price = product.product_price;
		titularData.total = product.product_price;
		props.saveState('titular', { ...props.titular, ...titularData });
		setShowInfo('');
	};

	/* A function that is being called when the checkbox is checked or unchecked. */
	const cleanPhilHealth = useCallback(
		(phil_health) => {
			if (!phil_health) setOpenModalPhilHealth(true);

			const { titular, saveState } = props;
			let titularTmp = {};

			// titularTmp.phil_health_number = '';
			// titularTmp.second_nationality = 'default';
			// titularTmp.second_dni = '';

			if (phil_health === false && titular?.formErrors?.phil_health_number)
				delete titular?.formErrors?.phil_health_number;

			if (phil_health === true) {
				if (titular?.formErrors?.second_dni)
					delete titular?.formErrors?.second_dni;
				if (titular?.formErrors?.second_nationality)
					delete titular?.formErrors?.second_nationality;
			}

			const data = {
				...titular,
				phil_health_number: '',
				second_nationality: 'default',
				second_dni: '',
				phil_health
			};

			saveState('titular', data);
		},
		[props]
	);

	/**
	 * Change phil_health value (effect)
	 */
	useEffect(() => {
		const { phil_health } = props.titular;

		setActivePhilHealth(phil_health);
	}, [props?.titular?.phil_health]);

	const handleCloseModal = () => setOpenModalPhilHealth(false);

	/**
	 * Form titular inputs
	 *
	 * @param {Object} titular
	 */
	const titularInputs = useCallback(
		(titular) => {
			const {
				is_edit,
				show_in_step_two,
				is_profile,
				getTitularDocument,
				isAlly,
				isAllyMaster,
				isAllyMatrix,
				isAdmin,
				isLeadOperator,
				isCoordinator,
				token,
				updating,
				user_type,
				isSuper,
				no_offers_coupons,
				readOnlyPlans,
				handleChangePassword,
				customers,
				isoCode,
				interval,
				isAllyTitularRegister = false
			} = props;

			return (
				<div className="flex flex-wrap w-full">
					{is_edit !== true &&
						show_in_step_two !== false &&
						is_profile !== true && (
							<span className="text-2xl text-gray-800 font-poppins font-bold">
								Enter the holder&apos;s information
							</span>
						)}
					<div className="flex flex-wrap mt-4 headline-form">
						<TextInput
							name="first_name"
							label="First Name"
							placeholder="Enter first name"
							helperText={titular.formErrors?.first_name}
							errors={titular.formErrors?.first_name}
							value={titular.first_name}
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							onChange={handleInput}
							onBlur={handleBlur}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile ? '' : 'cssHelperTextError'
								}
							}}
							disabled={titular.NotEditCustomer}
						/>

						<TextInput
							name="middle_name"
							label="Middle Name"
							placeholder="Enter middle name"
							helperText={titular.formErrors?.middle_name}
							errors={titular.formErrors?.middle_name}
							value={titular.middle_name}
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							onChange={handleInput}
							onBlur={handleBlur}
							required={false}
							FormHelperTextProps={{
								classes: { root: is_profile ? '' : 'cssHelperTextError' }
							}}
							disabled={titular.NotEditCustomer}
						/>

						<TextInput
							name="last_name"
							label="Last Name"
							placeholder="Enter last name"
							helperText={titular.formErrors?.last_name}
							errors={titular.formErrors?.last_name}
							value={titular.last_name}
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							onChange={handleInput}
							onBlur={handleBlur}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile ? '' : 'cssHelperTextError'
								}
							}}
							disabled={titular.NotEditCustomer}
						/>

						<DateInput
							name="birth_date"
							label="Date of birth"
							placeholder="mm/dd/yyyy"
							required={true}
							maxDate={
								new Date(new Date().setFullYear(new Date().getFullYear() - 18))
							}
							helperText={titular.formErrors?.birth_date}
							errors={titular.formErrors?.birth_date}
							value={titular.birth_date}
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							onChange={(date) => handleBirthDate(date)}
							disabled={titular.NotEditCustomer}
						/>

						<SelectSimpleOutlined
							name="sex"
							label="Gender"
							value={titular.sex}
							placeholder="Select gender"
							listItems={SEXS}
							addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
								titular.sex === 'default' && 'default'
							}`}
							helperText={titular.formErrors?.sex}
							errors={titular.formErrors?.sex}
							onChange={handleInputSex}
							onBlur={handleBlur}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile ? '' : 'cssHelperTextError'
								}
							}}
							disabled={titular.NotEditCustomer}
						/>

						{titular.include_titular && !is_profile && (
							<>
								<SelectSimpleOutlined
									name="civil_status"
									label="Civil Status"
									value={titular.civil_status}
									placeholder="Select civil status"
									listItems={MARITAL_STATUS}
									addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
										titular.civil_status === 'default' && 'default'
									}`}
									helperText={titular.formErrors?.civil_status}
									errors={titular.formErrors?.civil_status}
									onChange={handleInput}
									onBlur={handleBlur}
									required={true}
									FormHelperTextProps={{
										classes: {
											root: is_profile ? '' : 'cssHelperTextError'
										}
									}}
									disabled={titular.NotEditCustomer}
								/>
							</>
						)}

						{/* Identifier... */}
						<SelectSimpleOutlined
							name="nationality"
							label="Type of ID Number"
							value={titular.nationality}
							placeholder="Select ID type"
							listItems={NATIONALITIES}
							addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
								(titular.nationality === 'default' ||
									(customers && customers.length > 0 && is_profile)) &&
								'default'
							}`}
							helperText={titular.formErrors?.nationality}
							errors={titular.formErrors?.nationality}
							onChange={handleInput}
							onBlur={handleBlur}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile ? '' : 'cssHelperTextError'
								}
							}}
							disabled={
								(customers && customers.length > 0
									? true
									: updating && !isAdmin
									? true
									: false) || titular.NotEditCustomer
							}
						/>

						<TextInput
							name="dni"
							label="ID Number"
							placeholder="Enter ID number"
							helperText={titular.formErrors?.dni}
							errors={titular.formErrors?.dni}
							value={titular.dni}
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							onChange={handleInput}
							onBlur={handleBlur}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile ? '' : 'cssHelperTextError'
								}
							}}
							disabled={
								(customers && customers.length > 0
									? true
									: updating && !isAdmin
									? true
									: false) || titular.NotEditCustomer
							}
						/>

						<FileWithIconInput
							name="document_dni"
							label="Upload of Identity Document (ID)"
							required={true}
							value={titular.document_dni}
							ifExist={
								titular.document_dni && getTitularDocument ? true : false
							}
							showView={titular.document_dni === 'Uploaded file' ? true : false}
							placeholder="Upload ID"
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							changeClass={false}
							errors={titular.formErrors?.doc_dni}
							helperText={titular.formErrors?.doc_dni}
							onChange={(e) => handleInputFile(e)}
							onClick={() => getTitularDocument(titular._id)}
							disabled={titular.NotEditCustomer}
						/>
						{titular.include_titular && !is_profile && (
							<div className="wInputThree groupRadio">
								<Tooltip
									title="Note: When changing, the data entered will be deleted."
									placement="top"
									arrow
								>
									<FormControl component="fieldset">
										<FormLabel component="legend" className="mb-2">
											PhilHealth Member
										</FormLabel>
										<RadioGroup
											row
											aria-label="phil_health"
											name="phil_health"
											value={titular.phil_health}
											onChange={handleInput}
										>
											<FormControlLabel
												value={true}
												control={<RadioInput />}
												label="Yes"
												className="mr-10"
											/>
											<FormControlLabel
												value={false}
												control={<RadioInput />}
												label="No"
											/>
										</RadioGroup>
									</FormControl>
								</Tooltip>
							</div>
						)}

						{activePhilHealth && !is_profile && titular.include_titular ? (
							<>
								<TextInput
									name="phil_health_number"
									label="PhilHealth Number"
									placeholder="PhilHealth Number"
									helperText={titular.formErrors?.phil_health_number}
									errors={titular.formErrors?.phil_health_number}
									value={titular.phil_health_number}
									addClass="wInputThree"
									onChange={handleInput}
									onBlur={handleBlur}
									required={titular?.phil_health}
									disabled={titular.NotEditCustomer}
									FormHelperTextProps={{
										classes: {
											root: is_profile ? '' : 'cssHelperTextError'
										}
									}}
								/>
								<div className="wInputThree" />
							</>
						) : (
							titular.include_titular &&
							!is_profile && (
								<>
									<SelectSimpleOutlined
										name="second_nationality"
										label="Type of Second ID Number"
										value={titular.second_nationality}
										placeholder="Select second ID type"
										listItems={nationalities}
										addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
											titular.second_nationality === 'default' && 'default'
										}`}
										helperText={titular.formErrors?.second_nationality}
										errors={titular.formErrors?.second_nationality}
										onChange={handleInput}
										onBlur={handleBlur}
										required={!titular?.phil_health}
										FormHelperTextProps={{
											classes: {
												root: is_profile ? '' : 'cssHelperTextError'
											}
										}}
										disabled={titular.NotEditCustomer}
									/>

									<TextInput
										name="second_dni"
										label="ID Number"
										placeholder="Enter ID number"
										helperText={titular.formErrors?.second_dni}
										errors={titular.formErrors?.second_dni}
										value={titular.second_dni}
										addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
										onChange={handleInput}
										onBlur={handleBlur}
										required={!titular?.phil_health}
										FormHelperTextProps={{
											classes: {
												root: is_profile ? '' : 'cssHelperTextError'
											}
										}}
										disabled={titular?.NotEditCustomer}
									/>
								</>
							)
						)}

						<Tooltip
							open={
								router.pathname === '/profile' &&
								isChangeEmail &&
								!titular.email_updated &&
								!titular.email_confirm &&
								titular.mode === 'email'
							}
							title="Note: Check that the email is correct, since you can only change it once."
							placement="top"
							arrow
						>
							<TextInput
								name="email"
								label="Email Address"
								placeholder="Enter email address "
								type="email"
								helperText={titular.formErrors?.email}
								errors={titular.formErrors?.email}
								value={titular.email}
								addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
								onChange={handleInput}
								onBlur={handleBlur}
								required={true}
								FormHelperTextProps={{
									classes: { root: is_profile ? '' : 'cssHelperTextError' }
								}}
								disabled={
									((isAdmin || isSuper || isLeadOperator || isCoordinator) &&
									updating === false
										? false
										: (token && user_type === 1) ||
										  (token && (isAlly || isAllyMaster || isAllyMatrix)) ||
										  updating
										? true
										: false) || titular.NotEditCustomer
								}
							/>
						</Tooltip>

						<CellPhoneInput
							label="Mobile Number"
							addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
							value={titular.phone_one}
							onChange={(val) => handlePhone(val, 'phone_one')}
							onBlur={handleBlurPhone}
							error={titular.formErrors?.phone_one}
							required={true}
							disabled={titular.NotEditCustomer}
						/>

						<div
							className={`${
								titular.include_titular && !is_profile ? 'wInputThree' : ''
							}`}
						/>
						{!is_profile && (
							<TextField
								select
								name="country"
								label="Country"
								helperText={titular.formErrors?.country}
								variant="outlined"
								className={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
									(titular.country === 'default' ||
										(is_profile && titular.include_titular) ||
										titular.NotEditCustomer) &&
									'default'
								}`}
								FormHelperTextProps={{
									classes: {
										root: is_profile ? '' : 'cssHelperTextError'
									}
								}}
								InputLabelProps={{
									classes: {
										root:
											titular.country && !titular.formErrors?.country
												? 'cssLabel'
												: (!titular.country && titular.formErrors?.country) ||
												  titular.formErrors?.country
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!titular.country && !titular.formErrors?.country
												? 'cssInput'
												: (!titular.country && titular.formErrors?.country) ||
												  titular.formErrors?.country
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								SelectProps={{
									IconComponent: KeyboardArrowDownIcon
								}}
								margin="normal"
								error={true}
								value={titular.country || ''}
								required={true}
								onChange={handleInputCountry}
								onBlur={handleBlurCountry}
								disabled={
									(is_profile && titular.include_titular) ||
									titular.NotEditCustomer
								}
							>
								<MenuItem value="default" hidden disabled>
									Select the country
								</MenuItem>
								{titular.include_titular && (
									<MenuItem value={COUNTRY_NAME}>{COUNTRY_NAME}</MenuItem>
								)}
								{(titular.include_titular === false ||
									!titular.include_titular ||
									is_profile ||
									isAdmin) &&
									CountryList.map((country, i) => (
										<MenuItem key={i} value={country.name_en}>
											{country.name_en}
										</MenuItem>
									))}
							</TextField>
						)}

						{titular.include_titular === true && !is_profile && (
							<Location
								userData={titular}
								save={props.saveState}
								handleSetAlert={setAlert}
								type="titular"
								states={props.states}
								actions={props.actions}
								is_profile={props.is_profile}
							/>
						)}

						{(titular.include_titular === false || !titular.include_titular) &&
							!is_profile && (
								<TextInput
									name="city"
									label="City"
									placeholder="City"
									helperText={titular.formErrors?.city}
									errors={titular.formErrors?.city}
									value={titular.city === 'default' ? '' : titular.city}
									addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
									onChange={handleInput}
									onBlur={handleBlur}
									required={true}
									FormHelperTextProps={{
										classes: {
											root: is_profile ? '' : 'cssHelperTextError'
										}
									}}
									disabled={titular.NotEditCustomer}
								/>
							)}
						{!is_profile && (
							<TextInput
								name="address"
								label="Home Address"
								placeholder="Enter home address"
								helperText={titular.formErrors?.address}
								errors={titular.formErrors?.address}
								value={titular.address}
								addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
								onChange={handleInput}
								onBlur={handleBlur}
								required={true}
								FormHelperTextProps={{
									classes: {
										root: is_profile ? '' : 'cssHelperTextError'
									}
								}}
								disabled={titular.NotEditCustomer}
							/>
						)}

						{is_profile && (
							<Tooltip
								title={
									titular.status
										? "If you don't have a password, you can create it here"
										: 'You cannot change the password because your user has not been activated yet'
								}
							>
								<TextField
									name="password"
									label="Password"
									placeholder="********"
									type="text"
									helperText={titular.formErrors?.password}
									variant="outlined"
									className="wInputThree"
									FormHelperTextProps={{
										classes: {
											root: is_profile ? '' : 'cssHelperTextError'
										}
									}}
									InputLabelProps={{
										classes: {
											root: 'cssLabel'
										},
										shrink: true
									}}
									InputProps={{
										classes: {
											notchedOutline: 'cssInput'
										},
										endAdornment: (
											<InputAdornment position="end">
												<button
													className={`${
														titular.status ? 'text-pink-500' : 'text-gray-500'
													} text-small tracking-extra cursor-pointer text-center font-bold font-poppins uppercase`}
													aria-label="change password"
													onClick={() => handleChangePassword(true)}
													disabled={!titular.status}
												>
													CHANGE
												</button>
											</InputAdornment>
										)
									}}
									margin="normal"
									value=""
									disabled={true}
									onChange={handleInput}
								/>
							</Tooltip>
						)}

						{isAllyTitularRegister &&
							!no_offers_coupons &&
							isAllyUser &&
							is_profile !== true && (
								<div className="flex w-full">
									<FormControlLabel
										className="nm w-60"
										label={
											<span
												className={`text-gray-800 text-base ${
													titular.promo_code ? 'font-bold' : 'font-normal'
												}`}
											>
												Give 5% discount coupon
											</span>
										}
										control={
											<Checkbox
												name="promo_code"
												required={true}
												className={
													titular.promo_code ? 'checkBoxActive' : 'checkBox'
												}
												onClick={(e) =>
													handleCheckbox(e, titular.promo_code ? false : true)
												}
												checked={titular.promo_code || false}
												disabled={titular.NotEditCustomer}
											/>
										}
									/>
								</div>
							)}

						{isAllyTitularRegister && isAllyUser && is_profile !== true && (
							<div className="flex w-full">
								<FormControlLabel
									className="nm w-60"
									label={
										<span
											className={`text-gray-800 text-base ${
												titular.assisted_selling ? 'font-bold' : 'font-normal'
											}`}
										>
											I would like a Big Broder to help with membership.
											<Tooltip title="I would like a Big Broder to help with membership">
												<span className="font-bold text-purple-500"> (?)</span>
											</Tooltip>
										</span>
									}
									control={
										<Checkbox
											name="assisted_selling"
											required={true}
											className={
												titular.assisted_selling ? 'checkBoxActive' : 'checkBox'
											}
											onClick={(e) =>
												handleCheckboxAssistedSelling(
													e,
													titular.assisted_selling ? false : true
												)
											}
											checked={titular.assisted_selling || false}
											disabled={titular.NotEditCustomer}
										/>
									}
								/>
							</div>
						)}

						{titular.assisted_selling && (
							<div className="bg-purple-200 p-3 rounded mb-5">
								<p>
									With this option, one of our commercial advisors will get in
									contact with the holder to explain the product in detail,
									answering any doubts and boosting affiliations.
								</p>
							</div>
						)}
						{((is_profile !== true && titular.include_titular && !isAdmin) ||
							(is_profile !== true &&
								titular.include_titular &&
								isAdmin &&
								titular.self_paid)) && (
							<div className="w-full mt-4 mx-2 hidden md:block">
								<table
									className="border-gray-300 border bg-white w-full"
									cellPadding="10"
									cellSpacing="0"
								>
									<tbody>
										<tr className="h-16">
											<td>
												<span className="text-gray-800 text-base font-bold leading-relaxed pl-2">
													Select Plan
												</span>
											</td>
											{Array.isArray(titular.products) &&
												titular.birth_date &&
												titular.products.map((product, i) => (
													<td key={i}>
														<FormControlLabel
															name="product"
															value={String(product._id)}
															control={
																<Radio
																	value={String(product._id)}
																	className={
																		titular.product_id === product._id
																			? 'checkBoxActive'
																			: 'checkBox'
																	}
																	checked={
																		titular.product_id === product._id
																			? true
																			: false
																	}
																/>
															}
															onChange={() => handleSelectPlan(product)}
															label={
																<span
																	className={`text-gray-800 text-normal ${
																		titular.product_id === product._id
																			? 'font-bold'
																			: 'font-normal'
																	}`}
																>
																	{product.name}
																</span>
															}
															labelPlacement="end"
															disabled={
																!!readOnlyPlans || titular.NotEditCustomer
															}
														/>
													</td>
												))}
											{loading && (
												<td align="start">
													<CircularProgress
														className="text-gray-300 m-1"
														size={24}
														thickness={3}
													/>
												</td>
											)}
											{!titular.birth_date && (
												<td className="text-gray-600 text-normal">
													You must enter your date of birth
												</td>
											)}
											<td className="w-48">
												<span className="text-normal leading-relaxed">
													{titular.total && titular.product_type ? (
														<span className="text-gray-800 text-base">
															{isoCode && isoCode.toUpperCase()}.
															{formatFloat(
																titular?.product_type?.intervals[interval][
																	isoCode
																]
															)}
														</span>
													) : (
														<span className="text-gray-600">
															{isoCode && isoCode.toUpperCase()}.0
														</span>
													)}
												</span>
												<span className="ml-1">
													<SelectInterval />
												</span>
											</td>
											<td align="end">
												<span className="pr-2">
													<SelectCurrency hideFlag={true} />
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
						{/* RESPONSIVE PLANS */}
						{((is_profile !== true && titular.include_titular && !isAdmin) ||
							(is_profile !== true &&
								titular.include_titular &&
								isAdmin &&
								titular.self_paid)) && (
							<div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
								<div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
									<span className="text-gray-800 text-normal font-bold">
										Select the plan
									</span>
								</div>
								<div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
									<span className="font-bold">Plan</span>
									<div className="flex flex-col items-end">
										{titular.products &&
											titular.products.length > 0 &&
											titular.products.map((product, i) => (
												<span key={i} className="m-b5">
													<FormControlLabel
														key={i}
														name="product"
														value={String(product._id)}
														control={
															<Radio
																value={String(product._id)}
																className={
																	titular.product_id === product._id
																		? 'checkBoxActive'
																		: 'checkBox'
																}
																checked={
																	titular.product_id === product._id
																		? true
																		: false
																}
															/>
														}
														onChange={() => handleSelectPlan(product)}
														label={
															<span
																className={`text-gray-800 text-normal ${
																	titular.product_id === product._id
																		? 'font-bold'
																		: 'font-normal'
																}`}
															>
																{product.name}
															</span>
														}
														labelPlacement="start"
														disabled={
															!!readOnlyPlans || titular.NotEditCustomer
														}
													/>
												</span>
											))}
									</div>
								</div>
								<div className="text-gray-800 text-normal flex items-center justify-end px-5 min-h-50p">
									<span>
										{titular.total && titular?.product_type ? (
											<span
												className={`text-gray-800 ${
													isMobile ? 'text-xs' : 'text-sm'
												}`}
											>
												{isoCode && isoCode.toUpperCase()}.
												{formatFloat(
													titular?.product_type?.intervals[interval][isoCode]
												)}
											</span>
										) : (
											<span className="text-gray-600">
												{isoCode && isoCode.toUpperCase()}.0
											</span>
										)}
										<span className="ml-2">
											<SelectInterval />
										</span>
										<span className="ml-2">
											<SelectCurrency hideFlag={true} />
										</span>
									</span>
								</div>
							</div>
						)}
						<SnackBar
							openAlert={alert.openAlert}
							messageAlert={alert.messageAlert}
							handleClose={handleClose}
						/>

						<BasicModal open={openModalPhilHealth} onClose={handleCloseModal}>
							<ChildModalPhilHealth onClose={handleCloseModal} />
						</BasicModal>
					</div>
				</div>
			);
		},
		[props, activePhilHealth, openModalPhilHealth]
	);

	return titularInputs(props.titular);
};

export default HeadlineForm;

import React, { Fragment, memo } from 'react';
import DOMPurify from 'isomorphic-dompurify';

const sanitizer = DOMPurify.sanitize;

export const data = [
	{
		icon: '/images/indicators-gray/img-5.svg',
		title: 'Inclusive',
		description:
			'no prior medical exams required, enrollment up to 85 years old',
		paddingTop: true
	},
	{
		icon: '/images/indicators-gray/img-6.svg',
		title: 'Access to over 288 private clinics',
		description: 'hospitals throughout the country',
		paddingTop: true
	},
	{
		icon: '/images/indicators-gray/cobros-extra.svg',
		title: 'No extra payments',
		description: 'no guarantees, deductibles or upfront costs'
	},
	{
		icon: '/images/indicators-gray/img-3.svg',
		title: 'Coverage up to PHP 300,000',
		description: 'per year'
	},
	{
		icon: '/images/indicators-gray/img-4.svg',
		title: 'Support and experience',
		description:
		"from Maxicare, Generika Drugstore, Home Health Care Inc, Lifeline 16-911, Argonix and Hi-Precision Diagnostics",
		paddingTop: true
	},
	{
		icon: '/images/indicators-gray/img-2.svg',
		title: 'Affordable prices',
		description: 'Most of our clients pay less than USD 19 per month'
	}
];

const Card = ({ icon, title, description }) => {
	return (
		<>
			<style>
				{`
					@media only screen and (min-width: 1280px) and (max-width: 1420px) {
						.title-card {
							font-size: 17px !important;
						}
						.subtitle-card {
							font-size: 15px !important;
						}
          }
					@media only screen and (min-width: 1420px) and (max-width: 1650px) {
						.title-card {
							font-size: 18px !important;
						}
						.subtitle-card {
							font-size: 16px !important;
						}
          }
        `}
			</style>
			<div
				style={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)', padding: 22 }}
				className="flex items-center bg-white box-border rounded-md border border-gray-500 border-solid mt-2 mx-2 mb-8 h-40"
			>
				<div
					className="flex items-center justify-center"
					style={{ marginRight: 16 }}
				>
					<img src={icon} alt={title} className="w-16 h-16 min-w-16" />
				</div>
				<div className="lg:h-25 xl:h-auto xl:flex justify-center flex-col">
					<h2
						className="title-card font-bold font-poppins mb-1 text-lg lg:text-xl"
						dangerouslySetInnerHTML={{ __html: sanitizer(title) }}
					/>

					<p
						className="subtitle-card leading-relaxed text-gray-900 pointer-events-none font-medium font-poppins text-sm sm:text-base lg:text-lg"
						dangerouslySetInnerHTML={{ __html: `${sanitizer(description)}.` }}
					/>
				</div>
			</div>
		</>
	);
};

const HireComponent = () => (
	<div className="mt-6 md:mt-20 flex justify-start items-center flex-col w-full px-0 sm:px-10 lg:px-32">
		<h2 className="text-purple-500 text-center font-poppins text-2xl md:text-4xl leading-snug font-semibold px-5 mb-8">
			Why choose{' '}
			<span className="text-pink-500 font-poppins font-semibold">
				asistensi{' '}
			</span>
			?
		</h2>

		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-1">
			{data.map((item, i) => {
				return (
					<Fragment key={i}>
						<Card
							icon={item.icon}
							title={item.title}
							description={item.description}
							paddingTop={item.paddingTop}
						/>
					</Fragment>
				);
			})}
		</div>
	</div>
);

export default memo(HireComponent);

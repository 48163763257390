import React from 'react';

export const paymentMethod = (method) => {
	if (method === 'E') {
		return 'Efectivo';
	} else if (method === 'C') {
		return 'Cheque';
	} else if (method === 'Z') {
		return 'Zelle';
	} else if (method === 'S' || method === 'B') {
		return 'Tarjeta de Crédito';
	} else if (method === 'T') {
		return 'Transferencia';
	} else if (method === 'P') {
		return 'PayPal';
	}
};

export const paymentStatus = (status, chip = false) => {
	const statusMessages = {
		succeeded: 'Successful',
		active: 'Active',
		denied: 'Denied',
		canceled: 'Canceled',
		annulled: 'Annulled',
		expired: 'Annulled',
		trialing: 'Free Trial',
		free_trial: 'Free Trial',
		unpaid: 'payable',
		failed_payment: 'failed payment',
		suspended: 'Suspended',
		'not-complete': 'To complete'
	};

	if (status === 'pending') {
		if (chip) return 'To be approved';
		return 'Pending approval';
	} else if (statusMessages[status]) {
		return statusMessages[status];
	} else return '';
};

export const paymentInterval = (param) => {
	if (param === 'month') {
		return 'Monthly';
	} else if (param === 'year') {
		return 'Annual';
	} else if (param === 'week') {
		return 'Semanal';
	} else if (param === 'day') {
		return 'Diario';
	} else if (param === 'bimonthly') {
		return 'Semiannual';
	} else if (param === 'quarterly') {
		return 'Quarterly';
	} else if (param === 'semiannual') {
		return 'Semiannual';
	} else {
		return '';
	}
};

export const paymentStatusInvoice = (status) => {
	if (status === 'paid') {
		return 'Pagado';
	} else if (status === 'draft') {
		return 'Próximo pago';
	} else if (status === 'open') {
		return 'Por pagar';
	} else if (status === 'uncollectible') {
		return 'Factura Incobrable';
	} else if (status === 'void') {
		return 'Devolución';
	} else {
		return '';
	}
};

export const chipPaymentStatus = (status) => {
	return (
		<>
			{(status === 'succeeded' || status === 'active') && (
				<span className="text-xs bg-green-100 text-green-200 py-2 px-3 rounded-full">
					{paymentStatus(status, true)}
				</span>
			)}
			{(status === 'pending' || status === 'not-complete') && (
				<span className="text-xs bg-purple-200 text-purple-500 py-2 px-3 rounded-full">
					{paymentStatus(status, true)}
				</span>
			)}
			{(status === 'trialing' ||
				status === 'free_trial' ||
				status === 'unpaid') && (
				<span className="text-xs bg-pink-200 text-pink-500 py-2 px-3 rounded-full">
					{paymentStatus(status, true)}
				</span>
			)}
			{(status === 'denied' ||
				status === 'canceled' ||
				status === 'annulled' ||
				status === 'failed_payment' ||
				status === 'expired') && (
				<span className="text-xs bg-gray-300 text-gray-800 py-2 px-3 rounded-full">
					{paymentStatus(status, true)}
				</span>
			)}
		</>
	);
};

export const paymentNames = {
	E: 'Cash',
	P: 'PayPal',
	Z: 'Zelle',
	T: 'Transfer',
	S: 'Debit or Credit Card',
	C: 'Cheque'
};
